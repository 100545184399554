import GroupModel from "model/Groups/Class";
import {
  GroupsInterface,
  SchoolInterface,
} from "model/Groups/interfaces/groups";
import api from "services/api";

export interface GroupParams {
  type?: GroupsInterface["type"];
  parent?: number | string;
  shift?: number | string;
  serie?: number | string;
  name?: string;
}

class GroupService {
  public listGroups = async (
    params?: GroupParams
  ): Promise<GroupsInterface[]> => {
    return await api.get("groups/groups/", { params });
  };

  public createGroup = async (props: GroupModel): Promise<GroupsInterface> => {
    return await api.post("groups/groups/", props);
  };

  public updateGroup = async (props: GroupModel): Promise<GroupsInterface> => {
    return await api.patch(`groups/groups/${props.id}/`, props);
  };

  public deleteGroups = async (props: GroupsInterface): Promise<void> => {
    return await api.delete(`groups/groups/${props.id}/`);
  };

  public createSchool = async (
    props: SchoolInterface
  ): Promise<GroupsInterface> => {
    return await api.post("groups/groups/", props);
  };

  public removeSchool = async (
    props: SchoolInterface
  ): Promise<GroupsInterface> => {
    return await api.delete(`groups/groups/${props.id}/`);
  };

  public updateSchool = async (
    props: SchoolInterface
  ): Promise<GroupsInterface> => {
    return await api.patch(`groups/groups/${props.id}/`, props);
  };
}
export default new GroupService();
