import React from "react";
import { Modal } from "components/Modal";
import { Typography } from "@mui/material";
import { GroupsInterface } from "model/Groups/interfaces/groups";
import classServices from "store/reducers/Groups/AsyncActions/group.services";

interface Props {
  classToDelete: GroupsInterface;
  open: boolean;
  onClose: () => void;
}
const DeleteClassModal: React.FC<Props> = ({
  classToDelete,
  open,
  onClose,
}) => {
  const handleRemoveClass = () => {
    classServices.deleteGroups(classToDelete).then(() => {
      onClose();
    });
  };
  return (
    <Modal
      isOpen={open}
      onClose={onClose}
      title="Confirmar remoção"
      submitButtonText="Remover Turma"
      onSubmit={handleRemoveClass}
    >
      <Typography variant="body1">
        Você realmente deseja apagar a turma <b>{classToDelete?.name}</b>?
      </Typography>
    </Modal>
  );
};

export default DeleteClassModal;
