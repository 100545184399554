import AlertService from "services/admin/AlertService"
import { ShiftInterface } from "model/Groups/interfaces/shift"
import shiftServices from "./shift.services";
import { loadShiftsData } from "../Actions/action";


export const selectShifts = () => {
    return (dispatch) => {
        shiftServices.listShifs().then(({ data }: any) => {
            dispatch(loadShiftsData(data as ShiftInterface[]));
        })
    }
}



export const registerShift = (props: ShiftInterface) => {
    return (dispatch: any) => {
        return shiftServices.createShift(props).then(
            () => {
                AlertService.showAlertSuccessDefault({})
                dispatch(listAllShifts());
            }
        ).catch((error) => {
            if (error.response) {
                AlertService.showAlertErroDefault({ message: " - " + Object.values(error.response.data) })
            }

        });
    };
};


export const listAllShifts = () => {
    return (dispatch: any) => {
        return shiftServices.listShifs().then((data) => {
            let shifts: ShiftInterface[] = [];
            const entryes: ShiftInterface[] = data['data']['results'];
            for (const entry of entryes) {
                shifts.push(entry);
            }
            // dispatch(listUsers(users));
        }).catch((error) => {
            if (error.response) {
                AlertService.showAlertErroDefault({ message: " - " + Object.values(error.response.data) })
            }
        });
    };
};


