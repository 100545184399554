import { useEffect, useState } from "react";

import { useHistory, useLocation } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import { Button } from "@mui/material";

import { ButtonAccountMenu } from "../ButtonAccountMenu";
import { MenuNavigationApps } from "components/MenuNavigationApps";

import logo from "../../assets/logos/logo.svg";

import { login, setUserData } from "store/reducers/Authentication/Actions";

import { ILoginProps } from "store/reducers/Authentication/Actions/types";
import { IRegisterProps } from "store/reducers/Authentication/AsyncActions/types";

import "./styles.css";

interface IItemsOfNavigation {
  name: string;
  route: string;
  isSelected: boolean;
  isExclusive?: boolean;
}

interface INavbar {
  titleApp?: string;
  itemsOfNavigation?: IItemsOfNavigation[];
}

export function Navbar({ titleApp = '', itemsOfNavigation = [] }: INavbar) {
  const dispath = useDispatch()
  const history = useHistory();
  const { pathname } = useLocation()
  const { user } = useSelector((state: any) => state.authentication);

  const [listItemsOfNavigation, setListItemsOfNavigation] = useState(itemsOfNavigation)

  function handleNavigation(route: string) {
    setListItemsOfNavigation(prevState => (prevState.map(itemPrevState => {
      const routeEquals = itemPrevState.route === route
      const routeContains = route.startsWith(itemPrevState.route)

      if (itemPrevState.isExclusive) {
        return routeEquals ?
            { ...itemPrevState, isSelected: true } :
            { ...itemPrevState, isSelected: false }
      } else {
        return routeContains ?
        { ...itemPrevState, isSelected: true } :
        { ...itemPrevState, isSelected: false }
      }
    })))
  }

  useEffect(() => handleNavigation(pathname), [pathname])

  useEffect(() => {
    let appSession: any = sessionStorage.getItem("@App:S");
    let dataUserSession: any = sessionStorage.getItem("@App:userData");

    if (appSession) {
      appSession = JSON.parse(window.atob(appSession));
      dispath(login({ ...appSession } as ILoginProps));
    }

    if (dataUserSession) {
      dataUserSession = JSON.parse(window.atob(dataUserSession));
      dispath(setUserData({ ...dataUserSession } as IRegisterProps));
    }
  }, [
    dispath,
    sessionStorage.getItem("@App:S"),
    sessionStorage.getItem("@App:userData"),
  ]);

  if (!user.signed) {
    return <></>;
  }

  return (
    <nav className="navbar">
      <div className="logo">
        <img src={logo} alt="CEnPE" />
        {titleApp && <span>{titleApp}</span>}
      </div>

      <ul className="navigations">
        {
          listItemsOfNavigation.map(itemNavigation => (
            <li key={itemNavigation.name} className={`${itemNavigation.isSelected && "selected"}`}>
              <Button
                className="item_navigation_button"
                onClick={() => history.push(itemNavigation.route)}
              >
                {itemNavigation.name}
              </Button>
            </li>    
          ))
        }
      </ul>

      <div className="user">
        <MenuNavigationApps />

        <ButtonAccountMenu />
      </div>
    </nav>
  );
};

export default Navbar;
