import { AssessmentInterface } from "model/Groups/interfaces/assessment";
import { GroupExam } from "services/admin/Interfaces/Types";
import api from "services/api";

class AssessmentService {
  public async loadAssessment() {
    return await api.get<AssessmentInterface[]>("assessments/assessments/");
  }

  public async loadAssessmentById(id: number) {
    return await api.get<AssessmentInterface[]>(
      "assessments/assessments/" + id
    );
  }

  public async createGroupExam(data: GroupExam) {
    return await api.post<GroupExam>("assessments/creategroupexams/", data);
  }
}
export default new AssessmentService();
