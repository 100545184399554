import { ReactNode, useState } from "react"

import { TableHeader, ITableHeader } from "./TableHeader"

import { Pagination } from "@mui/material";

import SearchOffIcon from '@mui/icons-material/SearchOff'

import './styles.css'

export interface ITbodyTr {
    tbodyTrTd: {
        element: string | ReactNode;
    }[]
}

interface ITable extends ITableHeader {
    tbodyTrs?: ITbodyTr[];
    pagination: {
        totalRecords: number;
        numberActualPage: number;
        rowsPerPage: number;
        handlePaginationChange: (event: unknown, page: number) => void;
    }
    serverSide?: boolean
}

export function Table({ headers, tbodyTrs, pagination, setHeaders, serverSide = true }: ITable) {
    const INICIO = pagination.totalRecords > 0 ? (pagination.numberActualPage <= 1 ? 1 : (pagination.numberActualPage - 1) * pagination.rowsPerPage) : 0
    const ATE = pagination.numberActualPage * pagination.rowsPerPage
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {        
        pagination.handlePaginationChange(event, newPage)
        setPage(newPage);
    };

    const currentPageData = serverSide === false ? tbodyTrs?.slice((page - 1) * rowsPerPage, page * rowsPerPage) : tbodyTrs

    return (
        <div>
            <table className="table_custom">
                <TableHeader headers={headers} setHeaders={setHeaders} />

                <tbody>
                    {
                        currentPageData?.map((tr, index) => (
                            <tr key={index}>
                                {
                                    tr.tbodyTrTd.map((td, position) => (
                                        <td key={position}>
                                            <div className="tbody_custom_td">
                                                {td.element}
                                            </div>
                                        </td>
                                    ))
                                }
                            </tr>
                        ))
                    }
                </tbody>
            </table>

            <div className="pagination_custom">
                <span>{`${INICIO} a ${ATE <= pagination.totalRecords ? ATE : pagination.totalRecords} de ${pagination.totalRecords} registros`}</span>

                <Pagination
                    count={Math.ceil(pagination.totalRecords / pagination.rowsPerPage)}
                    variant="outlined"
                    shape="rounded"
                    page={serverSide === false ? page : pagination.numberActualPage}
                    onChange={serverSide === false ? handleChangePage : pagination.handlePaginationChange}
                />
            </div>
        </div>
    )
}