import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { IRegisterProps } from "store/reducers/Authentication/AsyncActions/types";

export default function StudentAssessmentDeleteDialog({
  open,
  setOpen,
  dataForm,
  deleteFunction,
  onClose,
}) {
  return (
    <Dialog open={open} keepMounted onClose={() => setOpen(false)}>
      <DialogTitle id="alert-dialog-slide-title">
        <Typography variant="h5">
          Deletar Avaliação de{" "}
          <b>
            {dataForm
              ? (dataForm as IRegisterProps).first_name +
                " " +
                (dataForm as IRegisterProps).last_name
              : null}
          </b>
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant="h5">
          Ao excluir a Avaliação, não será mais possível recuperar
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button onClick={() => onClose()}>Cancelar</Button>
        <Button onClick={() => deleteFunction()} color="error">
          Excluir definitivamente
        </Button>
      </DialogActions>
    </Dialog>
  );
}
