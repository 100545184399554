import { Box, MenuItem } from "@mui/material";
import { TextField, Select, InputLabel, FormControl } from "@mui/material";
import { Button, Divider, Grid } from "@mui/material";
import { useState } from "react";
import {
  Assessment,
  Exam,
  StudentExam,
} from "services/admin/Interfaces/Types";
import { StudentAssessmentsService } from "store/reducers/assessments/AsyncActions/assessment.service";
import AlertService from "utils/Alerts/AlertService";

interface IStudentAssessmentFormModal {
  student: number;
  allExams: Exam[];
  allAssessment: Assessment[];
  onClose: () => void;
  postCreateAction: () => void;
}

const StudentAssessmentFormModal = ({
  student,
  allExams,
  allAssessment,
  postCreateAction,
  onClose,
}: IStudentAssessmentFormModal) => {
  const [assessmentId, setAssessementId] = useState<number>(0);
  const [examId, setExamId] = useState<number>(0);
  const [code, setCode] = useState<string>("");

  const getExams = () => {
    if (!allAssessment) return [];

    const selecteAssessments: any = allAssessment.find(
      (assessment) => assessment.id === assessmentId
    );

    if (!selecteAssessments) return [];

    let exams: any[] = [];
    selecteAssessments.exams.forEach((element) => {
      const filteredElement = allExams.filter((exam) => {
        return element.exam === exam.id;
      });

      if (filteredElement) {
        exams = [...exams, ...filteredElement];
      }
    });
    return exams;
  };

  const handleSaveUser = () => {
    const studentExam: StudentExam = {
      code: code,
      student: student,
      exam: examId,
      assessment: assessmentId,
    };

    StudentAssessmentsService.createAssessmentsByStudent(studentExam)
      .then((response) => {
        AlertService.showAlert({
          type: "success",
          message: "Avaliação criada com sucesso!",
        });
        postCreateAction();
      })
      .catch(() => {
        AlertService.showAlert({
          type: "erro",
          message: "Não foi possivel criar avaliação!",
        });
      });
  };

  return (
    <>
      <form>
        <Grid container spacing={1} sx={{ paddingTop: "8px" }}>
          <Grid xs={12} item>
            <TextField
              label="Código"
              required
              fullWidth
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              value={code}
              onChange={(e) => setCode(e.target.value)}
            />
          </Grid>

          <Grid xs={6} item>
            <Box>
              <FormControl fullWidth>
                <InputLabel id="assessment">Avaliação</InputLabel>
                <Select
                  labelId="assessment"
                  label="Avaliação"
                  value={assessmentId}
                  required
                  fullWidth
                  onChange={(e) => {
                    setAssessementId(e.target.value as number);
                  }}
                >
                  {allAssessment
                    ? allAssessment.map((assessment) => (
                        <MenuItem
                          key={"av_" + assessment.id}
                          value={assessment.id}
                        >
                          {assessment.description}
                        </MenuItem>
                      ))
                    : null}
                </Select>
              </FormControl>
            </Box>
          </Grid>

          <Grid xs={6} item>
            <Box>
              <FormControl fullWidth>
                <InputLabel id="exam">exame</InputLabel>
                <Select
                  value={examId}
                  labelId="exam"
                  label="Exame"
                  required
                  fullWidth
                  onChange={(e) => {
                    setExamId(e.target.value as number);
                  }}
                >
                  {getExams().map((exam) => (
                    <MenuItem key={"exam_" + exam.id} value={exam.id}>
                      {exam.description}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>

          <Grid xs={12} item>
            <Divider style={{ margin: "1rem 0" }} variant="fullWidth" />
          </Grid>
          <Grid display="flex" justifyContent="flex-end" item xs={12}>
            <Button
              sx={{ marginRight: "30px" }}
              variant="contained"
              color="secondary"
              onClick={() => onClose()}
            >
              Cancelar
            </Button>

            <Button variant="contained" onClick={() => handleSaveUser()}>
              Salvar
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default StudentAssessmentFormModal;
