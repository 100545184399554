import { Button } from "@material-ui/core";
import { Box } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";

const Forbiden: React.FC = () => {
  const history = useHistory();

  const logout = () => {
    history.go(0);
    sessionStorage.clear();
    history.push("/");
  };

  return (
    <div className="container mt-5">
      <Box
        display={"flex"}
        alignItems={"center"}
        flexDirection={"column"}
        justifyContent={"space-between"}
      >
        <p style={{ marginTop: "24px", marginBottom: "24px" }}>
          Carregando...
        </p>        
      </Box>
    </div>
  );
};

export default Forbiden;
