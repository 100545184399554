import { createReducer } from "@reduxjs/toolkit"
import { ShiftInterface } from "model/Groups/interfaces/shift";
import { loadShiftsData, resetShiftData } from "./Actions/action";

const INITIAL_STATE = {
    shifts: [] as ShiftInterface[],
}



const shifts = createReducer(
    INITIAL_STATE, {
    [resetShiftData.type]: (state) => ({ ...state, shifts: [...INITIAL_STATE.shifts] }),
    [loadShiftsData.type]: (state, action) => {
        state.shifts = action.payload;
    }


}
);

export default shifts;