import { Button } from "@mui/material";

import './styles.css'

interface ICard {
  title: string;
  subTiltle?: string;
  buttonTitle: string;
  action: () => void;
}

const CardMenu = (props: ICard) => {
  return (
    <div className="card-menu-item-container">
      <h1>{props.title}</h1>

      <h3>{props.subTiltle}</h3>

      <Button
        variant="contained"
        color="primary"
        fullWidth
        size="large"
        onClick={props.action}
      >
        Gerenciar
      </Button>
    </div>
  );
};

export default CardMenu;
