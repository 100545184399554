import axios from "axios";

const DEFAULT_BASE_URL = "https://dev-api-platform.cenpe.ufc.br/";

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL || DEFAULT_BASE_URL,
});

/**
 * Setando o token global no header das requisições
 */
api.interceptors.request.use((config) => {
  let data: any = sessionStorage.getItem("@App:S");

  if (data) {
    data = JSON.parse(window.atob(data));
  }

  if (config.headers) {
    if (!!data) {
      config.headers["Authorization"] = `Bearer ${data.user.token}`;
    } else {
      config.headers["Authorization"] = "";
    }
  }

  return config;
});

export default api;

export const recordApi = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL || DEFAULT_BASE_URL,
});