import React from "react";
import { Modal } from "components/Modal";
import ClassForm from "../ClassForm";
import { GroupsInterface } from "model/Groups/interfaces/groups";

interface Props {
  classToEdit: GroupsInterface;
  open: boolean;
  onClose: () => void;
}
const EditClassModal: React.FC<Props> = ({ classToEdit, open, onClose }) => {
  const handleEditClass = () => {
    // classServices.deleteGroups(classToDelete.id!);
  };
  return (
    <Modal
      isOpen={open}
      onClose={onClose}
      title="Edição de Turma"
      withAction={false}
    >
      <ClassForm
        type="edit"
        initialDataEdit={classToEdit}        
        onSuccess={() => {
          onClose();
        }}
      />
    </Modal>
  );
};

export default EditClassModal;
