import { createReducer } from "@reduxjs/toolkit";
import { GroupsInterface } from "model/Groups/interfaces/groups";
import { loadCities, loadGroupsData, loadSchools, resetGroupsData } from "./Actions/action";

const INITIAL_STATE = {
  groups: [] as GroupsInterface[],
  schools: [] as GroupsInterface[],
  cities: [] as GroupsInterface[],
};

const groups = createReducer(INITIAL_STATE, {
  [resetGroupsData.type]: (state) => ({
    ...state,
    groups: [...INITIAL_STATE.groups],
  }),
  [loadGroupsData.type]: (state, action) => {
    state.groups = action.payload;
  },
  [loadSchools.type]: (state, action) => {
    state.schools = action.payload;
  },
  [loadCities.type]: (state, action) => {
    state.cities = action.payload;
  },
});

export default groups;
