import { toIRegisterProps, toIUserData } from "utils/userDataConverter";
import AuthenticationServices from "store/reducers/Authentication/AsyncActions/authentication.services";
import { IRegisterProps } from "store/reducers/Authentication/AsyncActions/types";
import { listUsers } from "../../Actions/userRegister";
import { IUserData } from "./types";
import AlertService from "services/admin/AlertService";


export const registerUser = (userData: IUserData) => {
  return (dispatch: any) => {
    return AuthenticationServices.register(toIRegisterProps(userData)).then(
      () => {
        AlertService.showAlertSuccessDefault({})
        dispatch(listAllUsers());
      }
    ).catch((error) => {
      if (error.response) {
        AlertService.showAlertErroDefault({ message: " - " + Object.values(error.response.data) })
      }

    });
  };
};

export const updateUsers = (userData: IUserData, functionExecInEndRequest?: { ifSuccess?: () => void; ifError?: () => void;  }) => {
  return (dispatch: any) => {
    return AuthenticationServices.updateUser(toIRegisterProps(userData)).then(
      () => {
        AlertService.showAlertSuccessDefault({})
        dispatch(listAllUsers());
        
        if (functionExecInEndRequest && functionExecInEndRequest?.ifSuccess) functionExecInEndRequest?.ifSuccess()
      }
    ).catch((error) => {
      if (functionExecInEndRequest && functionExecInEndRequest?.ifError) functionExecInEndRequest?.ifError()

      if (error.response) {
        if (error.response.data.group.length > 0) {
          if (error.response.data.group[0].includes('This field may not be null')) {
            AlertService.showAlertErroDefault({ message: 'Necessário informar uma turma' })

            return
          }
        }

        AlertService.showAlertErroDefault({ message: " - " + Object.values(error.response.data) })
      }
    });
  };
};

export const updateUsersWithCallbackFuntion = (
  userData: IUserData,
  callbackFunction: any
) => {
  return (dispatch: any) => {
    return AuthenticationServices.updateUser(toIRegisterProps(userData)).then(
      () => {
        AlertService.showAlertSuccessDefault({})
        callbackFunction();
      }
    ).catch((error) => {
      if (error.response) {
        AlertService.showAlertErroDefault({ message: " - " + Object.values(error.response.data) })
      }
    });
  };
};

export const deleteUser = (userData: IUserData) => {
  return (dispatch: any) => {
    return AuthenticationServices.deleteUser(toIRegisterProps(userData)).then(
      () => {
        AlertService.showAlertDeleteDefault({})
        dispatch(listAllUsers());
      }
    ).catch((error) => {
      if (error.response) {
        AlertService.showAlertErroDefault({ message: " - " + Object.values(error.response.data) })
      }
    });
  };
};

export const deleteUserWithCallBackFunction = (
  userData: IUserData,
  callbackFunction: any
) => {
  return (dispatch: any) => {
    return AuthenticationServices.deleteUser(toIRegisterProps(userData)).then(
      () => {
        AlertService.showAlertDeleteDefault({})
        callbackFunction();
      }
    ).catch((error) => {
      if (error.response) {
        AlertService.showAlertErroDefault({ message: " - " + Object.values(error.response.data) })
      }
    });
  };
};

export const listAllUsers = () => {
  return (dispatch: any) => {
    return AuthenticationServices.getUsers("").then((data) => {
      let users: IUserData[] = [];
      const entryes: IRegisterProps[] = data['data']['results'];
      for (const entry of entryes) {
        users.push(toIUserData(entry));
      }
      dispatch(listUsers(users));
    }).catch((error) => {
      if (error.response) {
        AlertService.showAlertErroDefault({ message: " - " + Object.values(error.response.data) })
      }
    });
  };
};

export const listUsersPaged = (
  page: number,
  userRole: string | undefined,
  getPageDataFunction: any,
  userFullName?: string,
) => {
  return (dispatch: any) => {
    const params = `offset=${(page - 1) * 20}&limit=20`

    const role = userRole ? "&profile__type=" + userRole : "";

    const full__name = userFullName ? `&full_name=${userFullName}`  : ''

    AuthenticationServices.getUsers(params + role + full__name).then((data) => {
      getPageDataFunction(data['data']);
      
      let results: IUserData[] = [];
      const entryes: IRegisterProps[] = data['data']['results'];


      for (const entry of entryes) {
        results.push(toIUserData(entry));
      }
      dispatch(listUsers(results));
    }).catch((error) => {
      if (error.response) {
        AlertService.showAlertErroDefault({ message: " - " + Object.values(error.response.data) })
      }
    });
  };
};
