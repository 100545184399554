import AlertService from "services/admin/AlertService"
import { SerieInterface } from "model/Groups/interfaces/series";
import serieServices from "./serie.services";
import { loadSeriesData } from "../Actions/action";


export const selectSeries = () => {
    return (dispatch) => {
        serieServices.listSeries().then(({ data }: any) => {
            dispatch(loadSeriesData(data as SerieInterface[]));
        })
    }
}


export const registerSerie = (props: SerieInterface) => {
    return (dispatch: any) => {
        return serieServices.createSerie(props).then(
            () => {
                AlertService.showAlertSuccessDefault({})
                dispatch(listAllSeries());
            }
        ).catch((error) => {
            if (error.response) {
                AlertService.showAlertErroDefault({ message: " - " + Object.values(error.response.data) })
            }

        });
    };
};


export const listAllSeries = () => {
    return (dispatch: any) => {
        return serieServices.listSeries().then((data) => {
            let series: SerieInterface[] = [];
            const entryes: SerieInterface[] = data['data']['results'];
            for (const entry of entryes) {
                series.push(entry);
            }
            // dispatch(listUsers(users));
        }).catch((error) => {
            if (error.response) {
                AlertService.showAlertErroDefault({ message: " - " + Object.values(error.response.data) })
            }
        });
    };
};


