import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
    typography: {
        fontFamily: 'Poppins, Roboto',
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                    transition: 'all 0.2s linear',
                    fontFamily: 'Poppins',
                    fontSize: '1.6rem',
                    ':hover': {
                        boxShadow: 'none',
                    }
                },
                containedPrimary: {
                    fontFamily: 'Poppins',
                    color: "var(--neutral-1)",
                    backgroundColor: "var(--primary-regular)",
                    '&:hover': {
                        backgroundColor: "var(--primary-regular)",
                        filter: 'brightness(0.9)',
                        color: "var(--neutral-1)",
                    }
                },
                containedSecondary: {
                    fontFamily: 'Poppins',
                    color: "var(--primary-regular)",
                    backgroundColor: "var(--primary-lighter)",
                    '&:hover': {
                        color: "var(--primary-regular)",
                        backgroundColor: "var(--primary-lighter)",
                        filter: 'brightness(0.9)',
                    }
                },
                sizeLarge: {
                    padding: '14px'
                },
                sizeMedium: {
                    padding: '8px 16px',
                    fontSize: '1.4rem'
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                sizeSmall: {
                    fontSize: 16,
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                sizeSmall: {
                    fontSize: 16,
                }
            }
        },
        MuiPaginationItem: {
            styleOverrides: {
                root: {
                    fontSize: '1.4rem',
                    fontWeight: 500
                },
                page: {
                    '&.Mui-selected': {
                        backgroundColor: 'var(--primary-dark) !important',
                        color: 'var(--neutral-1)'
                    }
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    fontSize: '1.6rem'
                },

            }
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    fontSize: '1.4rem'
                },
                
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    fontSize: '1.4rem'
                },
                
            }
        },
    }
});
