import "./main.css";
import { Route, Switch } from "react-router-dom";
import Admin from "pages/Admin";
import { useSelector } from "react-redux";
import Forbiden from "pages/Forbiden";
import React from "react";
import ClassGroup from "pages/Admin/Groups/ClassGroup";
import StudentPage from "./../pages/Admin/Students/index";
import Home from "pages/Admin/Home/Home";
import StudentAssessmentComponent from "pages/Admin/Students/components/studentAssessment/studentAssessment";
import SchoolList from "pages/Admin/Schools/SchoolList";

const OuterRoutes: React.FC = () => {
  const { userData } = useSelector((state: any) => state.authentication);
  const userAdmin = userData && userData.type === "administrator";

  const userUnauthorized =
    !userData || userData.type === "student" || userData.type === "teacher";

  return (
    <React.Fragment>
      {userUnauthorized ? (
        <Switch>
          <Route path="/" exact component={Forbiden} />
        </Switch>
      ) : (
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/admin" component={Admin} />
          <Route path="/groups/class" exact component={ClassGroup} />
          <Route path="/groups/students" exact component={StudentPage} />
          <Route
            path="/groups/students/:student/assessments/"
            exact
            component={StudentAssessmentComponent}
          />

          {userAdmin ? <Route path="/schools" component={SchoolList} /> : <></>}

          {/* <Route path="*" component={NotFound} /> */}
        </Switch>
      )}
    </React.Fragment>
  );
};
export default OuterRoutes;
