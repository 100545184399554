import { GroupsInterface } from "../interfaces/groups";

export default class GroupModel implements GroupsInterface {
  public readonly type: "class" = "class";
  public parent?: number;
  constructor(
    public name: string,
    public serie: number,
    public shift: number,
    public id?: number
  ) {}

  validadeFields() {
    return this.name && this.serie > 0 && this.shift > 0;
  }

  setParent(parentId: number) {
    this.parent = parentId;
  }
}

export function getEmptyGroupData(): GroupsInterface {
  return {
    id: undefined,
    name: "",
    serie: -1,
    shift: -1,
    parent: -1,
    type: "class",
  };
}
