import { Button } from "@mui/material";

import './styles.css'

interface ITitlePage {
  title: string;
  subtitle: string;
  buttonTitle?: string;
  buttonAction?: () => void;
}

export default function TitlePage({ title, subtitle, buttonTitle, buttonAction }: ITitlePage) {
  return (
    <div className="title_page_container">
      <div>
        <h1>{title}</h1>
        <h2>{subtitle}</h2>
      </div>

      {
        buttonTitle &&
        <Button
          variant="contained"
          size="large"
          onClick={buttonAction}
        >
          {buttonTitle}
        </Button>
      }
    </div>
  );
}
