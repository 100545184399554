import React, { FormEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Button,
  TextField,
  InputAdornment,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

import EditOutlineIcon from '@mui/icons-material/EditOutlined'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'

import TitlePage from "components/Title/Title";
import UserDialog from "./UserDialog";
import UserDeleteDialog from "./UserDeleteDialog";

import {
  listUsersPaged,
} from "store/reducers/UserRegister/AsyncActions/userRegister";
import { IUserData } from "store/reducers/UserRegister/AsyncActions/userRegister/types";
import { findGroups } from "store/reducers/Groups/AsyncActions";

import { getEmptyUserData } from "utils/userDataConverter";
import {
  filterGroupsByUser,
  getGroupById,
  getRolesByUser,
} from "utils/userFormUtils";

import { SearchIconCustom } from "./Style";
import { ITbodyTr, Table } from "components/Table";
import { IItemHeader } from "components/Table/TableHeader";

const INITIAL_DATA_PAGINATION = {
  count: 0,
  next: "",
  previous: "",
  results: [],
};

export default function UserList() {
  const dispatch = useDispatch();

  const [searchByName, setSearchByName] = useState<string>("");
  const [searchByProfile, setSearchByProfile] = useState<string>("todos");
  const [dataUsersFiltered, setDataUsersFiltered] = useState<ITbodyTr[]>([])
  const [headersTable, setHeaderTable] = useState([
    {
      id: 1,
      title: 'Nome',
      //with_order: 'init',
      //key_of_search: 'nome',
    },
    {
      id: 2,
      title: 'Sobrenome',
      //with_order: 'init',
      //key_of_search: 'sobrenome',
    },
    {
      id: 3,
      title: 'E-mail',
      //with_order: 'init',
      //key_of_search: 'email',
    },
    {
      id: 4,
      title: 'Perfil',
      //with_order: 'init',
      //key_of_search: 'perfil',
    },
    {
      id: 5,
      title: 'Turma Vinculado',
    },
    { id: 6 }
  ] as IItemHeader[])

  const [openDialogAdd, setOpenDialogAdd] = React.useState(false);
  const [openDialogDelete, setOpenDialogDelete] = React.useState(false);

  let dataUsers: IUserData[] = useSelector((state: any) => state.users).users;
  const { userData } = useSelector((state: any) => state.authentication);
  const { groups } = useSelector((state: any) => state.groups);

  const [configPagination, setConfigPagination] = useState(INITIAL_DATA_PAGINATION);
  const [page, setPage] = useState(1);

  const [dataForm, setDataForm] = useState<IUserData>(getEmptyUserData());

  const getUsers = () => {
    dispatch(listUsersPaged(page, undefined, setConfigPagination));
  };

  const handleFilter = (event: FormEvent) => {
    event.preventDefault()

    setPage(1)

    dispatch(
      listUsersPaged(
        1,
        searchByProfile === 'todos' ? undefined : searchByProfile,
        setConfigPagination,
        searchByName.trim() ? searchByName : ''
      ));
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    dispatch(
      listUsersPaged(
        newPage,
        searchByProfile === 'todos' ? undefined : searchByProfile,
        setConfigPagination,
        searchByName.trim() ? searchByName : ''
      ));
  };

  const getButtonsOfActionsForTable = (user: IUserData) => (
    <>
      <Button
        variant="outlined"
        color="inherit"
        size="small"
        aria-label="edit"
        startIcon={<EditOutlineIcon style={{ fontSize: '2rem' }} />}
        onClick={() => {
          setOpenDialogAdd(true);
          setDataForm(user);
        }}
      >
        Editar
      </Button>

      <Button
        variant="outlined"
        color="inherit"
        size="small"
        aria-label="delete"
        onClick={() => {
          setOpenDialogDelete(true);
          setDataForm(user);
        }}
      >
        <DeleteOutlineIcon style={{ fontSize: '2rem' }} />
      </Button>
    </>
  );

  const getStructuredDataUsersForTable = () => {
    const data = dataUsers.map((user: IUserData) => {
      return {
        tbodyTrTd: [
          { element: user.nome },
          { element: user.sobrenome },
          { element: user.email },
          { element: user.perfil },
          { element: getGroupById(user.group, filterGroupsByUser(userData, groups))?.name ?? '-' },
          { element: getButtonsOfActionsForTable(user) },
        ],
      };
    });

    setDataUsersFiltered(data);
  };

  /* 
  const searchUser = () => {
    let data: IUserData[] = dataUsers;

    if (data.length > 0) {

      // --- Filters 
      if (searchByName.trim() && (searchByProfile !== 'todos')) {
        data = data.filter((user: IUserData) => {
          return (
            ((user.nome !== null &&
              user.nome.toLowerCase().indexOf(searchByName.toLowerCase()) > -1) ||
            user.email === searchByName) && user.perfil === searchByProfile
          )
        })
      } else if (searchByName.trim()) {

        data = data.filter((user: IUserData) => {
          return (
            (user.nome !== null &&
              user.nome.toLowerCase().indexOf(searchByName.toLowerCase()) > -1) ||
            user.email === searchByName
          );
        });
      } else if (searchByProfile.trim() && searchByProfile !== 'todos') {
        data = data.filter((user: IUserData) => user.perfil === searchByProfile)
      }
      
      // --- Order
      const headerOrder = headersTable.filter(header => header.with_order && header.with_order !== 'init')

      if (headerOrder.length > 0) {
        const orderBy = headerOrder[0].key_of_search as any

        data = data.slice().sort(function (a, b) {
          if (a[orderBy] > b[orderBy]) {
            return headerOrder[0].with_order === 'asc' ? 1 : -1;
          }
          if (a[orderBy] < b[orderBy]) {
            return headerOrder[0].with_order === 'asc' ? -1 : 1;
          }

          return 0;
        });
      }
    }
    
    getStructuredDataUsersForTable(data);
  };
  */

  useEffect(() => {
    getUsers();
    dispatch(findGroups());
  }, [dispatch]);

  useEffect(() => getStructuredDataUsersForTable(), [dataUsers, headersTable])

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <div style={{ width: '90vw', margin: '2rem' }}>
        <TitlePage
          title={"Gerenciamento de Usuários"}
          subtitle={dataUsers.length + " registro(s) encontrado(s)"}
          buttonTitle="Adicionar Usuário"
          buttonAction={() => {
            setDataForm(getEmptyUserData());
            setOpenDialogAdd(true);
          }}
        />

        <form onSubmit={handleFilter} id="filters_user" style={{ display: 'flex', alignItems: 'center', gap: 10, padding: '2rem 0' }}>
          <TextField
            style={{ flex: 6 }}
            size="small"
            variant="outlined"
            placeholder="Pesquisar por nome, sobrenome ou email"
            value={searchByName}
            onChange={(e) => setSearchByName(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIconCustom />
                </InputAdornment>
              ),
            }}
          />

          <FormControl style={{ flex: 2 }} size="small">
            <InputLabel id="perfil">Perfil:</InputLabel>
            <Select
              labelId="perfil"
              label="Perfil:"
              value={searchByProfile}
              onChange={(e) => setSearchByProfile(e.target.value)}
            >
              <MenuItem value={'todos'}>Todos os perfis</MenuItem>
              <MenuItem value={'teacher'}>Teacher</MenuItem>
              <MenuItem value={'administrator'}>Administrator</MenuItem>
              <MenuItem value={'student'}>Student</MenuItem>
              <MenuItem value={'coordinator'}>Coordinator</MenuItem>
            </Select>
          </FormControl>

          <Button
            variant="contained"
            size="medium"
            style={{ flex: 1 }}
            type="submit"
            form="filters_user"
          >
            Pesquisar
          </Button>

          <Button
            variant="contained"
            color="secondary"
            size="medium"
            style={{ flex: 1 }}
            onClick={() => setSearchByName("")}
          >
            Limpar
          </Button>
        </form>

        <Table
          headers={headersTable}
          tbodyTrs={dataUsersFiltered}
          pagination={{
            numberActualPage: page,
            rowsPerPage: 20,
            totalRecords: configPagination.count,
            handlePaginationChange: handleChangePage
          }}
          setHeaders={setHeaderTable}
        />

        <UserDialog
          open={openDialogAdd}
          setOpen={setOpenDialogAdd}
          dataForm={dataForm}
          setDataForm={setDataForm}
          categories={getRolesByUser(userData)}
          userGroups={filterGroupsByUser(userData, groups)}
          refreshUpdateFunction={undefined}
          typeUser={undefined}
        />
        <UserDeleteDialog
          open={openDialogDelete}
          setOpen={setOpenDialogDelete}
          dataForm={dataForm}
          refreshFunction={undefined}
        />
      </div>
    </div>
  );
}
