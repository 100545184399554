import { createReducer } from "@reduxjs/toolkit";
import { listUsers, registerUser } from "./Actions/userRegister";
import { IUserData } from "./AsyncActions/userRegister/types";

const emptyUsers: IUserData[] = [];
const INITIAL_VALUES = {
  users: emptyUsers,
};

export default createReducer(INITIAL_VALUES, {
  [registerUser.type]: (state) => {
    state.users = emptyUsers;
  },

  [listUsers.type]: (state, action) => {
    state.users = action.payload;
  },
});
