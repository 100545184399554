import ClassGroup from "pages/Admin/Groups/ClassGroup";
import CheckAuth from "pages/Public/check-out";
import { Route, Switch } from "react-router-dom";
import NotFound from "../pages/404";

const SignRoutes = () => {
  return (
    <Switch>
      <Route path="/" exact component={CheckAuth} />
      {/* <Route path="*" component={NotFound} /> */}
    </Switch>
  );
};

export default SignRoutes;
