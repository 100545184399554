import { useState, useEffect, FormEvent } from "react";

import { useDispatch, useSelector } from "react-redux";

import {
  Alert,
  AlertColor,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
} from "@mui/material";

import EditOutlineIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import SaveAsIcon from "@mui/icons-material/SaveAs";

import { Modal } from "components/Modal";
import TitlePage from "components/Title/Title";
import ClassForm from "./components/ClassForm";
import DeleteClassModal from "./components/DeleteClassModal";
import EditClassModal from "./components/EditClassModal";
import { IItemHeader } from "components/Table/TableHeader";
import { ITbodyTr, Table } from "components/Table";

import { GroupsInterface } from "model/Groups/interfaces/groups";

import { findGroups, loadAllSchools } from "store/reducers/Groups/AsyncActions";
import { selectShifts } from "store/reducers/Shifts/AsyncActions";
import { selectSeries } from "store/reducers/Series/AsyncActions";

import "./style.scss";
import AddAssessmentClassModal from "./components/AddAssessmentModal";

export interface AlertInterface {
  open: boolean;
  message: string;
  type?: AlertColor;
}

const INITIAL_ALERT: AlertInterface = {
  message: "",
  open: false,
  type: "success",
};

const ClassGroup = () => {
  const dispatch = useDispatch();

  const [headersTable, setHeaderTable] = useState([
    {
      id: 1,
      title: "Nome",
      //with_order: 'init',
      //key_of_search: 'nome',
    },
    {
      id: 2,
      title: "Série",
      //with_order: 'init',
      //key_of_search: 'serie',
    },
    {
      id: 3,
      title: "Turno",
      //with_order: 'init',
      //key_of_search: 'turno',
    },
    { id: 4 },
  ] as IItemHeader[]);
  const [dataClassesFiltered, setDataClassesFiltered] = useState<ITbodyTr[][]>(
    []
  );
  const [page, setPage] = useState(1);

  const [alert, setAlert] = useState<AlertInterface>(INITIAL_ALERT);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedClasses, setSelectedClasses] =
    useState<GroupsInterface | null>(null);

  // Filters
  const [selectedShift, setSelectedShift] = useState(-1);
  const [selectedSerie, setSelectedSerie] = useState(-1);
  const [selectedSchool, setSelectedSchool] = useState(-1);

  const { groups, schools } = useSelector((state: any) => state.groups);
  const { series } = useSelector((state: any) => state.series);
  const { shifts } = useSelector((state: any) => state.shifts);

  const [openActions, setOpenActions] = useState({
    delete: false,
    edit: false,
    add_assessment: false,
  });

  const handleSearch = (event: FormEvent) => {
    event.preventDefault();

    dispatch(
      findGroups({
        parent: selectedSchool,
        shift: selectedShift > 0 ? selectedShift : "",
        serie: selectedSerie > 0 ? selectedSerie : "",
      })
    );
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const getButtonsOfActionsForTable = (myClass: GroupsInterface) => (
    <>
      <Button
        title="Adicionar Avaliação"
        variant="outlined"
        color="inherit"
        size="small"
        aria-label="edit"
        onClick={() => {
          setOpenActions({ edit: false, delete: false, add_assessment: true });
          setSelectedClasses(myClass);
        }}
      >
        <SaveAsIcon style={{ fontSize: "2rem" }} />
      </Button>

      <Button
        variant="outlined"
        color="inherit"
        size="small"
        aria-label="edit"
        startIcon={<EditOutlineIcon style={{ fontSize: "2rem" }} />}
        onClick={() => {
          setOpenActions({ edit: true, delete: false, add_assessment: false });
          setSelectedClasses(myClass);
        }}
      >
        Editar
      </Button>

      <Button
        variant="outlined"
        color="inherit"
        size="small"
        aria-label="delete"
        onClick={() => {
          setOpenActions({ edit: false, delete: true, add_assessment: false });
          setSelectedClasses(myClass);
        }}
      >
        <DeleteOutlineIcon style={{ fontSize: "2rem" }} />
      </Button>
    </>
  );

  const serchClasses = () => {
    /*
    const classes = sortBy(groups as GroupsInterface[], "id")
      .reverse()
      .filter((itemClass) => {
        return itemClass.type === "class";
      })
    */

    setPage(1);

    const LIMIT = 19;

    let tranferGroupsPaginated: ITbodyTr[][] = [];
    let armArrayAux: ITbodyTr[] = [];

    for (let index = 0; index < groups.length; index++) {
      armArrayAux.push({
        tbodyTrTd: [
          { element: groups[index].name },
          { element: String(groups[index].serie) },
          { element: String(groups[index].shift) },
          { element: getButtonsOfActionsForTable(groups[index]) },
        ],
      });

      if (index === LIMIT || index + 1 === groups.length) {
        tranferGroupsPaginated.push(armArrayAux);

        armArrayAux = [];
      }
    }

    setDataClassesFiltered(tranferGroupsPaginated);
  };

  useEffect(() => {
    dispatch(loadAllSchools());
    dispatch(selectShifts());
    dispatch(selectSeries());
  }, []);

  useEffect(() => serchClasses(), [groups]);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div style={{ width: "90vw", margin: "2rem" }}>
        <TitlePage
          title={"Gerenciamento de Turmas"}
          subtitle={
            selectedSchool <= 0
              ? ""
              : `${dataClassesFiltered.length} registro(s) encontrado(s)`
          }
          buttonTitle={selectedSchool <= 0 ? "" : "Adicionar Turma"}
          buttonAction={selectedSchool <= 0 ? () => {} : () => setIsOpen(true)}
        />

        {selectedSchool <= 0 ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginTop: 10,
              padding: 10,
              border: "1px solid var(--neutral-2)",
              borderRadius: 4,
              gap: 8,
            }}
          >
            <h1 style={{ color: "var(--primary-dark)" }}>
              Para visualizar as turmas, selecione uma escola
            </h1>

            <FormControl fullWidth>
              <InputLabel id="school-label">Escola:</InputLabel>

              <Select
                id="select-school"
                labelId="school-label"
                label="Escola:"
                onChange={(event) => {
                  setSelectedSchool(Number(event.target.value));
                  dispatch(
                    findGroups({ parent: event.target.value as number })
                  );
                }}
              >
                <MenuItem value={-1} disabled>
                  Selecione
                </MenuItem>
                {(schools as GroupsInterface[]).map(({ id, name }) => {
                  return (
                    <MenuItem key={id} value={id}>
                      {name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
        ) : (
          <>
            <form
              onSubmit={handleSearch}
              id="filters_classes"
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                padding: "2rem 0",
              }}
            >
              <FormControl style={{ flex: 6 }} size="small">
                <InputLabel id="school-label">Escola:</InputLabel>

                <Select
                  id="select-school"
                  labelId="school-label"
                  label="Escola:"
                  value={selectedSchool}
                  onChange={(event) =>
                    setSelectedSchool(Number(event.target.value))
                  }
                >
                  <MenuItem value={-1} disabled>
                    Selecione
                  </MenuItem>
                  {(schools as GroupsInterface[]).map(({ id, name }) => {
                    return (
                      <MenuItem key={id} value={id}>
                        {name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>

              <FormControl style={{ flex: 2 }} size="small">
                <InputLabel id="serie">Serie:</InputLabel>
                <Select
                  labelId="serie"
                  label="Serie:"
                  value={selectedSerie}
                  onChange={(e) => setSelectedSerie(e.target.value as number)}
                >
                  <MenuItem value={-1}>Todos</MenuItem>
                  {series.map((serie) => (
                    <MenuItem key={serie.id} value={serie.id}>
                      {serie.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl style={{ flex: 2 }} size="small">
                <InputLabel id="turno">Turno:</InputLabel>
                <Select
                  labelId="turno"
                  label="Turno:"
                  value={selectedShift}
                  onChange={(e) => setSelectedShift(e.target.value as number)}
                >
                  <MenuItem value={-1}>Todos</MenuItem>
                  {shifts.map((shift) => (
                    <MenuItem key={shift.id} value={shift.id}>
                      {shift.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <Button
                variant="contained"
                size="medium"
                style={{ flex: 1 }}
                type="submit"
                form="filters_classes"
              >
                Pesquisar
              </Button>

              <Button
                variant="contained"
                color="secondary"
                size="medium"
                style={{ flex: 1 }}
              >
                Limpar
              </Button>
            </form>

            <Table
              headers={headersTable}
              tbodyTrs={
                dataClassesFiltered.length > 0
                  ? dataClassesFiltered[page - 1]
                  : []
              }
              pagination={{
                numberActualPage: page,
                rowsPerPage: 20,
                totalRecords: groups.length,
                handlePaginationChange: handleChangePage,
              }}
              setHeaders={setHeaderTable}
            />

            <Modal
              isOpen={isOpen}
              onClose={() => setIsOpen(false)}
              title="Criar Turma"
              withAction={false}
            >
              <ClassForm
                onSuccess={() => {
                  setIsOpen(false);
                  dispatch(
                    findGroups({
                      parent: selectedSchool,
                      shift: selectedShift > 0 ? selectedShift : "",
                      serie: selectedSerie > 0 ? selectedSerie : "",
                    })
                  );
                }}
              />
            </Modal>

            <DeleteClassModal
              open={openActions.delete}
              onClose={() => {
                setOpenActions({ ...openActions, delete: false });
                dispatch(
                  findGroups({
                    parent: selectedSchool,
                    shift: selectedShift > 0 ? selectedShift : "",
                    serie: selectedSerie > 0 ? selectedSerie : "",
                  })
                );
              }}
              classToDelete={selectedClasses!}
            />

            <EditClassModal
              open={openActions.edit}
              onClose={() => {
                setOpenActions({ ...openActions, edit: false });
                dispatch(
                  findGroups({
                    parent: selectedSchool,
                    shift: selectedShift > 0 ? selectedShift : "",
                    serie: selectedSerie > 0 ? selectedSerie : "",
                  })
                );
              }}
              classToEdit={selectedClasses!}
            />
          </>
        )}
      </div>

      <AddAssessmentClassModal
        open={openActions.add_assessment}
        onClose={() => {
          setOpenActions({ ...openActions, add_assessment: false });
          dispatch(
            findGroups({
              parent: selectedSchool,
              shift: selectedShift > 0 ? selectedShift : "",
              serie: selectedSerie > 0 ? selectedSerie : "",
            })
          );
          //setAlert({
          //  message: "Avaliação cadastrada com sucesso",
          //  open: true,
          //  type: "success",
          //});
        }}
        classToEdit={selectedClasses!}
      />

      <Snackbar autoHideDuration={300} hidden open={alert.open}>
        <Alert severity={alert.type}>{alert.message}</Alert>
      </Snackbar>
    </div>
  );
};

export default ClassGroup;
