import { GroupsInterface } from "model/Groups/interfaces/groups";
import { IRegisterProps } from "store/reducers/Authentication/AsyncActions/types";
import { IUserData } from "store/reducers/UserRegister/AsyncActions/userRegister/types";

export function getGroupsByUser(dataUser: IRegisterProps): Object[] | null {
  return null;
}

export function getRolesByUser(dataUser: IRegisterProps | undefined): string[] {
  let roles: string[] = [];

  if (dataUser) {
    if (dataUser.type === "administrator") {
      roles.push("administrator");
      roles.push("coordinator");
      roles.push("teacher");
      roles.push("student");
    }

    if (dataUser.type === "coordinator") {
      roles.push("coordinator");
      roles.push("teacher");
      roles.push("student");
    }
  }
  return roles;
}

export function filterGroupsByUser(
  userData: IRegisterProps,
  groups: GroupsInterface[]
): GroupsInterface[] {




  if (userData['type'] === "administrator") {    
    return groups;
  } else
    return groups.filter((i) => {
      return (
        userData.group &&
        (i.parent === userData.group || i.id === userData.group)
      );
    });
}

export function filterGroupsByCategory(
  category: string,
  groups: GroupsInterface[]
): GroupsInterface[] {
  if (category === "student") {
    return groups.filter((i) => {
      return i.type === "class";
    });
  } else if (category === "teacher") {
    return groups.filter((i) => {
      return i.type === "school" || i.type === "class";
    });
  } else if (category === "coordinator") {
    return groups.filter((i) => {
      return i.type === "school";
    });
  } else {
    return [];
  }
}

export function getGroupById(
  id: number | undefined,
  groups: GroupsInterface[]
): GroupsInterface | undefined {
  if (id === undefined) return undefined;
  else
    return groups.filter((i) => {
      return i.id == id;
    })[0];
}
