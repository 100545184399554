import { IRegisterProps } from "store/reducers/Authentication/AsyncActions/types";
import { IUserData } from "store/reducers/UserRegister/AsyncActions/userRegister/types";

export function validateUser(
  dataUser: IUserData,
  passwordConfirmation: string
): string[] {
  let errors: string[] = [];
  if (!dataUser.id) {
    errors = errors.concat(
      validatePassWord(dataUser.senha, passwordConfirmation)
    );
  }

  return errors;
}

export function validatePassWord(
  password: string,
  passwordConfirmation: string
): string[] {
  let erros: string[] = [];
  if (!isFilled(password))
    erros.push(
      "É necessário informar a senha do usuário em processo de cadastro."
    );
  if (!isFilled(passwordConfirmation))
    erros.push("É necessário inserir a confirmação da senha.");
  if (password !== passwordConfirmation)
    erros.push("A senha está diferente da confirmação informada.");

  return erros;
}

function isFilled(field: string): boolean {
  return field !== undefined || field !== "";
}
