import {
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import ClassModel, { getEmptyGroupData } from "model/Groups/Class";
import { GroupsInterface } from "model/Groups/interfaces/groups";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  loadAllSchools,
  registerClass,
} from "store/reducers/Groups/AsyncActions";
import groupServices from "store/reducers/Groups/AsyncActions/group.services";
import { selectSeries } from "store/reducers/Series/AsyncActions";
import { selectShifts } from "store/reducers/Shifts/AsyncActions";

interface SelectInterface<T = string> {
  name: string;
  label: string;
  options: { show: string; value: T }[];
}

interface Props {
  onSuccess?(): void;
  type?: "edit" | "create";
  initialDataEdit?: GroupsInterface;
}

const INITIAL_DATA: GroupsInterface = {
  name: "",
  serie: -1,
  shift: -1,
  parent: -1,
  type: "class",
};

const ClassForm = ({ onSuccess, type = "create", initialDataEdit }: Props) => {
  const [data, setData] = useState<GroupsInterface>(
    type === "create" ? INITIAL_DATA : initialDataEdit!
  );

  const [dataForm, setDataForm] = useState<GroupsInterface>(
    getEmptyGroupData()
  );

  const { series } = useSelector((state: any) => state.series);
  const { shifts } = useSelector((state: any) => state.shifts);
  const { schools } = useSelector((state: any) => state.groups);

  useEffect(() => {
    dispatch(selectShifts());
    dispatch(selectSeries());
    dispatch(loadAllSchools());
  }, []);

  const dispatch = useDispatch();

  const MySelect = ({ name, label, options }: SelectInterface<number>) => {
    return (
      <FormControl fullWidth>
        <InputLabel id={`${name}-label`}>{label}</InputLabel>
        <Select
          name={name}
          labelId={`${name}-label`}
          required
          value={data[name]}
          label={label}
          onChange={(selected) =>
            setData({ ...data, [name]: selected.target.value as number })
          }
        >
          <MenuItem value={-1} disabled>
            Selecione
          </MenuItem>
          {options.map(({ value, show }) => {
            return (
              <MenuItem key={value} value={value}>
                {show}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  };

  const cleanFormField = (e: React.FormEvent) => {
    setDataForm(getEmptyGroupData());
  };

  const onChangeText = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };

  const onSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();

    const dataToSave = new ClassModel(data.name, data.serie, data.shift);

    if (dataToSave.validadeFields() && data.parent) {
      dataToSave.setParent(data.parent);
      if (type === "create") {
        dispatch(registerClass(dataToSave));
        if (onSuccess) {
          cleanFormField(e);
          onSuccess();
        }
      } else if (type === "edit") {
        dataToSave.id = initialDataEdit?.id;
        groupServices.updateGroup(dataToSave).then(() => {
          if (onSuccess) {
            cleanFormField(e);
            onSuccess();
          }
        });
      }
    }
  };

  return (
    <>
      <form action="" onSubmit={onSubmit}>
        <Grid container spacing={1} sx={{ paddingTop: "8px" }}>
          <Grid xs={6} item>
            <TextField
              name="name"
              required
              variant="outlined"
              value={data.name}
              onChange={onChangeText}
              fullWidth
              label="Nome"
              autoComplete="off"
            />
          </Grid>
          <Grid xs={6} item>
            <MySelect
              name="parent"
              label="Escola"
              options={schools.map(({ name, id }) => ({
                show: name,
                value: id,
              }))}
            />
          </Grid>
          <Grid xs={6} item>
            <MySelect
              name="serie"
              label="Série"
              options={series.map(({ name, id }) => ({
                show: name,
                value: id,
              }))}
            />
          </Grid>
          <Grid xs={6} item>
            <MySelect
              name="shift"
              label="Turno"
              options={shifts.map(({ name, id }) => ({
                show: name,
                value: id,
              }))}
            />
          </Grid>
          <Grid xs={12} item>
            <Divider style={{ margin: "1rem 0" }} variant="fullWidth" />
          </Grid>
          <Grid display="flex" justifyContent="flex-end" item xs={12}>
            <Button variant="contained" type="submit">
              Salvar
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default ClassForm;
