import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { SchoolInterface } from "model/Groups/interfaces/groups";
import { useDispatch } from "react-redux";
import { deleteSchool } from "store/reducers/Groups/AsyncActions";

export default function SchoolDeleteDialog({
  open,
  setOpen,
  dataForm,
  refreshFunction,
  onCloseAction,
}) {
  const dispatch = useDispatch();

  const handleDeleteUser = () => {
    dispatch(deleteSchool(dataForm as SchoolInterface));
    setOpen(false);
  };

  const onClose = () => {
    onCloseAction();
    setOpen(false);
  };

  return (
    <Dialog open={open} keepMounted onClose={() => setOpen(false)}>
      <DialogTitle id="alert-dialog-slide-title">
        <Typography variant="h4">
          Deletar escola <b>{(dataForm as any).nome}</b>
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant="h5">
          Ao excluir a escola, não será mais possível recuperar
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button onClick={() => onClose()}>Cancelar</Button>
        <Button onClick={handleDeleteUser} variant="contained" color="error">
          Excluir definitivamente
        </Button>
      </DialogActions>
    </Dialog>
  );
}
