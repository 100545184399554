import { SerieInterface } from "model/Groups/interfaces/series";
import api from "services/api";

class SerieService {

  public listSeries = async (): Promise<SerieInterface[]> => {
    return await api.get("groups/series/");
  }

  public createSerie = async (props: SerieInterface): Promise<SerieInterface> => {
    return await api.post(
      "groups/series/",
      props
    );
  }

  public updateSerie = async (props: SerieInterface): Promise<SerieInterface> => {
    return await api.patch(
      `groups/series/${props.id}`,
      props
    );
  }

  public deleteSeries = async (props: SerieInterface): Promise<void> => {
    return await api.delete(`groups/series/${props.id}/`);
  }

}
export default new SerieService();
