import {
  Breakpoint,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React from "react";
import { CancelButton } from "./components/CancelButton";

import "./style.scss";

export interface IProps {
  children?: React.ReactNode;
  isOpen: boolean;
  title: string;
  withAction?: boolean;
  closeButtonText?: string;
  submitButtonText?: string;
  onClose?: () => void;
  onSubmit?: () => void;
  size?: Breakpoint | false;
  disableSubmitButton?: boolean;
  fullScreen?: boolean;
}

export const Modal: React.FC<IProps> = ({
  isOpen,
  children,
  title,
  size,
  closeButtonText = "Cancelar",
  submitButtonText = "Cadastrar",
  withAction = true,
  disableSubmitButton = false,
  onClose,
  onSubmit,
  fullScreen = false,
}) => {
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  function executeOnClose() {
    if (onClose) onClose();
  }

  return (
    <Dialog
      open={open}
      fullWidth
      style={{ fontFamily: "Quicksand" }}
      maxWidth={size}
      id="ceModal"
      onClose={() => {
        setOpen(false);
        executeOnClose();
      }}
      fullScreen={fullScreen}
    >
      {/* titúlo */}
      <DialogTitle
        sx={{
          textAlign: "center",
          color: "#01618E",
          fontFamily: "Poppins",
          fontSize: "30px",
          fontWeight: 700,
        }}
      >
        {title}
      </DialogTitle>
      {/* conteúdo */}
      <DialogContent style={{ fontWeight: 500, textAlign: "center" }}>
        {children}
      </DialogContent>

      {/* botões */}
      {withAction && (
        <DialogActions sx={{ display: "flex" }}>
          <div className="ceModalAction" style={{}}>
            <div>
              <Button
                fullWidth
                variant="contained"
                disabled={disableSubmitButton}
                onClick={() => {
                  setOpen(false);
                  if (onSubmit) onSubmit();
                }}
              >
                {submitButtonText}
              </Button>
            </div>
            <div>
              <CancelButton
                closeButtonText={closeButtonText}
                onClick={() => {
                  setOpen(false);
                  executeOnClose();
                }}
              />
            </div>
          </div>
        </DialogActions>
      )}
    </Dialog>
  );
};
