import {
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import ClassModel from "model/Groups/Class";
import { AssessmentInterface } from "model/Groups/interfaces/assessment";
import { GroupsInterface } from "model/Groups/interfaces/groups";

import React from "react";
import AssessmentsServices from "store/reducers/Admin/AsyncActions/assessment/assessment.service";
import assessmentServices from "store/reducers/Assessment/AsyncActions/assessment.services";
import examServices from "store/reducers/Exams/AsyncActions/exam.services";

import { AlertInterface } from "../..";
import { ExamInterface } from "./../../../../../../model/Groups/interfaces/exams";
import { toast } from "react-toastify";

interface SelectInterface<T = string> {
  name: string;
  label: string;
  anothers?: any;
  options: { show: string; value: T }[];
}

interface IExamAssessment {
  id: number;
  assessment: number;
  exam: number;
}

interface Props {
  onSuccess?(): void;
  alert(alert: AlertInterface): void;
  type?: "edit" | "create";
  initialDataEdit?: GroupsInterface;
}

const INITIAL_DATA: GroupsInterface = {
  name: "",
  serie: -1,
  shift: -1,
  type: "class",
};
const INITIAL_DATA_EXAM: ExamInterface = {
  code: "",
  description: "",
  knowledge_area: -1,
  created_by: "",
  published: false,
  created_at: "",
  modified_at: "",
};

const AddAssessmentForm = ({
  onSuccess,
  alert,
  type = "create",
  initialDataEdit,
}: Props) => {
  const [data, setData] = React.useState<GroupsInterface>(
    type === "create" ? INITIAL_DATA : initialDataEdit!
  );

  const onSubmit = () => {
    alert({ message: "Salvo", open: true });
    assessmentServices
      .createGroupExam({
        assessment_id: assessmentFormId,
        exam_id: examSelected as number,
        group_id: data.id as number,
      })
      .then((response) => {
        if (onSuccess) {
          onSuccess();
        }
      });
  };

  const [assessment, setAssessment] = React.useState<AssessmentInterface[]>([]);
  const [exams, setExams] = React.useState<ExamInterface[]>([]);

  const [assessmentSelectedId, setAssessmentSelectedId] = React.useState<
    IExamAssessment[]
  >([]);

  const [assessmentFormId, setAssessmentFormId] = React.useState<number>(-1);

  const [examSelected, setExamSelected] = React.useState<number>(-1);

  React.useEffect(() => {
    const getAssessment = async () => {
      try {
        const data = await assessmentServices.loadAssessment();
        setAssessment(data.data["results"]);
      } catch (error) {
        toast.error("Erro ao importar os dados de avaliação");
      }
    };
    getAssessment();
  }, []);

  React.useEffect(() => {
    const getExamsByAssessment = async () => {
      try {
        const data = await assessmentServices.loadAssessmentById(
          assessmentFormId
        );
        setAssessmentSelectedId(data.data["exams"]);
      } catch (error) {
        toast.error("Erro ao importar dados da avaliação selecionada");
      }
    };
    if (assessmentFormId !== -1) {
      getExamsByAssessment();
    }
  }, [assessmentFormId]);

  React.useEffect(() => {
    const getExams = async () => {
      try {
        const resultsExam: any = await Promise.all(
          assessmentSelectedId.map(async (item: IExamAssessment) => {
            if (item) {
              const data = await examServices.loadExamById(item.exam);
              return data.data;
            }
          })
        );

        setExams(resultsExam);
      } catch (error) {
        toast.error("Erro ao carregar os Exames");
      }
    };

    if (assessmentSelectedId.length > 0) {
      getExams();
    }
  }, [assessmentSelectedId]);

  return (
    <>
      <form>
        <Grid container spacing={1} sx={{ paddingTop: "8px" }}>
          <Grid xs={6} item>
            <FormControl fullWidth>
              <InputLabel>Avaliação</InputLabel>
              <Select
                name="avaliacao"
                required
                onChange={(event: any) =>
                  setAssessmentFormId(event.target.value)
                }
              >
                <MenuItem disabled>Selecione</MenuItem>
                {assessment.map((avaliacao) => {
                  return (
                    <MenuItem key={avaliacao.id} value={avaliacao.id}>
                      {avaliacao.title}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>

          <Grid xs={6} item>
            <FormControl fullWidth>
              <InputLabel>Exame</InputLabel>
              <Select
                name="exam"
                required
                onChange={(event: any) => setExamSelected(event.target.value)}
              >
                <MenuItem disabled>Selecione</MenuItem>

                {exams.map((exam) => {
                  return (
                    <MenuItem key={exam.code} value={exam.id}>
                      {exam.description}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid xs={12} item>
            <Divider style={{ margin: "1rem 0" }} variant="fullWidth" />
          </Grid>
          <Grid display="flex" justifyContent="flex-end" item xs={12}>
            <Button variant="contained" onClick={() => onSubmit()}>
              Salvar
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default AddAssessmentForm;
