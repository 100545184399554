import {
  Button,
  Divider,
  Grid,
  TextField,
} from "@mui/material";
import { GroupsInterface } from "model/Groups/interfaces/groups";

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import AlertService from "services/admin/AlertService";
import { registerUser, updateUsers } from "store/reducers/UserRegister/AsyncActions/userRegister";
import { IUserData } from "store/reducers/UserRegister/AsyncActions/userRegister/types";
import { getEmptyUserData } from "utils/userDataConverter";
import { validateUser } from "utils/userValidator";

interface Props {
  onSuccess?(): void;
  type?: "edit" | "create";
  initialDataEdit?: GroupsInterface;
}

const StudentForm = ({
  onSuccess,
  type = "create",
}: Props) => {
  const [confirmacaoSenha, setConfirmacaoSenha] = useState("");

  const dispatch = useDispatch();

  const [dataForm, setDataForm] = useState<IUserData>(getEmptyUserData());

  const handleDataForm = (position: string, value: unknown) => {
    setDataForm({ ...dataForm, [position]: value });
  };

  const cleanFormField = (e: React.FormEvent) => {
    setDataForm(getEmptyUserData());
  };

  const handleSaveUser = (e: React.FormEvent) => {
    e.preventDefault();
    let errors: string[] = validateUser(dataForm, confirmacaoSenha);

    if (errors.length > 0) {
      AlertService.showAlert({ message: errors[0], type: "erro" })
    } else {
      if (type === "create") {

        dispatch(registerUser(dataForm));
        if (onSuccess) {
          cleanFormField(e);
          onSuccess();
        }
      } else if (type === "edit") {
        dispatch(updateUsers(dataForm));
        cleanFormField(e);        
      }
    }
  };

  return (
    <>
      <form onSubmit={handleSaveUser}>
        <Grid container spacing={1} sx={{ paddingTop: "8px" }}>
          <Grid xs={6} item>
            <TextField
              label="Nome"
              required
              fullWidth
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              value={dataForm.nome}
              onChange={(e) => handleDataForm("nome", e.target.value)}
            />
          </Grid>

          <Grid xs={6} item>
            <TextField
              label="Sobrenome"
              required
              fullWidth
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              value={dataForm.sobrenome}
              onChange={(e) => handleDataForm("sobrenome", e.target.value)}
            />
          </Grid>

          <Grid xs={6} item>
            <TextField
              label="Username"
              required
              fullWidth
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              value={dataForm.username}
              onChange={(e) => handleDataForm("username", e.target.value)}
            />
          </Grid>

          <Grid xs={6} item>
            <TextField
              label="E-mail"
              required
              fullWidth
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              value={dataForm.email}
              onChange={(e) => handleDataForm("email", e.target.value)}
            />
          </Grid>

          {!dataForm.id ? (
            <>
              <Grid xs={6} item>
                <TextField
                  label="Senha"
                  required
                  fullWidth
                  variant="outlined"
                  type="password"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={dataForm.senha}
                  onChange={(e) => handleDataForm("senha", e.target.value)}
                />
              </Grid>
              <Grid xs={6} item>
                <TextField
                  label="Confirmação Senha"
                  required
                  fullWidth
                  variant="outlined"
                  type="password"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={confirmacaoSenha}
                  onChange={(e) => setConfirmacaoSenha(e.target.value)}
                />
              </Grid>
            </>
          ) : null}

          <Grid xs={12} item>
            <Divider style={{ margin: "1rem 0" }} variant="fullWidth" />
          </Grid>

          <Grid display="flex" justifyContent="flex-end" item xs={12}>
            <Button variant="contained" type="submit">
              Salvar
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default StudentForm;
