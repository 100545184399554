import { AxiosResponse } from "axios";
import ClassModel from "model/Groups/Class";
import { ExamInterface } from "model/Groups/interfaces/exams";
import { GroupsInterface } from "model/Groups/interfaces/groups";
import api from "services/api";

class ExamService {
  public async loadExams() {
    return await api.get<ExamInterface[]>("assessments/exams/");
  }

  public async loadExamById(id: number) {
    return await api.get<ExamInterface[]>("assessments/exams/" + id);
  }
}
export default new ExamService();
