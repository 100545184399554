import { toast } from 'react-toastify';


export interface AlertsInterface {
    message?: string;
    time?: number;
    type?: string;
}

export default class AlertService {

    public static showAlert(props: AlertsInterface) {
        if (props.type === "erro") {
            toast.error(props.message, { autoClose: props.time });
        }
        if (props.type === "info") {
            toast.info(props.message, { autoClose: props.time });
        }
        if (props.type === "warn") {
            toast.warn(props.message, { autoClose: props.time });
        }
        if (props.type === "success") {
            toast.success(props.message, { autoClose: props.time });
        }

    }

    public static showAlertSuccessDefault(props: AlertsInterface) {
        toast.success("Cadastro realizado com sucesso !", { autoClose: props.time });
    }

    public static showAlertErroDefault(props: AlertsInterface) {
        toast.error("Erro ao realizar cadastro ! \n" + props.message, { autoClose: 5000 });

        
    }

    public static showAlertDeleteDefault(props: AlertsInterface) {
        toast.success("Registro removido com sucesso !", { autoClose: props.time });
    }

}
