import { IRegisterProps } from "store/reducers/Authentication/AsyncActions/types";
import { IUserData } from "store/reducers/UserRegister/AsyncActions/userRegister/types";

// export function toIRegisterProps(userData: IUserData): IRegisterProps {
//   const registerData: IRegisterProps = {
//     id: userData.id,
//     first_name: userData.nome,
//     last_name: userData.sobrenome,
//     password: userData.senha,
//     email: userData.email,
//     username: userData.email,
//     type: userData.perfil,
//     group: userData.group,
//   };
//   return registerData;
// }

// export function toIUserData(userData: IRegisterProps): IUserData {
//   const registerData: IUserData = {
//     id: userData.id,
//     nome: userData.first_name,
//     sobrenome: userData.last_name,
//     email: userData.email as string,
//     perfil: userData.type,
//     group: userData.group,
//     senha: userData.password as string,
//   };
//   return registerData;
// }

// export function toIUserDataMany(userData: IRegisterProps[]): IUserData[] {
//   let users: IUserData[] = [];
//   const entryes: IRegisterProps[] = userData;
//   for (const entry of entryes) {
//     users.push(toIUserData(entry));
//   }

//   return users;
// }

export function getEmptySchoolData(): any {
  return {
    id: undefined,    
    code: "",
    name: "",    
    parent: null
  };
}

export function getEmptySchoolDataRegister(): any {
  return {
    id: undefined,
    code: "",
    name: "",    
    parent: null
  };
}
