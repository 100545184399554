import { useAuth } from "@cenpe/auth";
import {
  Dialog,
  DialogContent,
  LinearProgress,
  Typography,
} from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { auth } from "store/reducers/Authentication/AsyncActions";

const CheckAuth = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    const { authData } = useAuth.getState();
    if (authData) {
      dispatch(auth(authData));
    }
  }, []);
  return (
    <div>
      <Dialog open={true}>
        <DialogContent>
          <Typography>Carregando dados...</Typography>
          <LinearProgress />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CheckAuth;
