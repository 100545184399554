import { IconButton } from '@mui/material';

import SwapVertIcon from '@mui/icons-material/SwapVert'
import NorthIcon from '@mui/icons-material/North';
import SouthIcon from '@mui/icons-material/South';

export interface IItemHeader {
    id: number;
    key_of_search?: string;
    title?: string;
    with_order?: 'init' | 'desc' | 'asc';
}

export interface ITableHeader {
    headers?: IItemHeader[]
    setHeaders?: React.Dispatch<React.SetStateAction<IItemHeader[]>>;
}

export function TableHeader({ headers = [], setHeaders = () => {} }: ITableHeader) {
    function handleOrder(itemHeader: IItemHeader) {
        const order: 'init' | 'desc' | 'asc' = itemHeader.with_order === 'init' ? 'asc' : itemHeader.with_order === 'asc' ? 'desc' : 'init'

        const headersFormated: IItemHeader[] = headers?.length > 0 ? headers?.map(header => {
            return itemHeader.id === header.id ?
                {...header, with_order: order} :
                header.with_order ?
                {...header, with_order: 'init'} :
                {...header}
        }) : []

        if (headersFormated.length > 0) {
            setHeaders(headersFormated)
        }
    }

    return (
        <thead>
            <tr>
                {
                    headers?.map(header => (
                        <th key={header.id}>
                            <div className="thead_custom_th">
                                {header.title}

                                {
                                    header.with_order &&
                                    <IconButton onClick={() => handleOrder(header)}>
                                        {
                                            header.with_order === 'asc' ?
                                            <NorthIcon /> :
                                            header.with_order === 'desc' ?
                                            <SouthIcon /> :
                                            <SwapVertIcon />
                                        }
                                    </IconButton>
                                }
                            </div>
                        </th>        
                    ))
                }
            </tr>
        </thead>
    )
}