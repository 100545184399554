import { useState } from 'react';

import { useSelector } from 'react-redux';

import { useAuth } from "@cenpe/auth";

import {
    Avatar,
    Menu,
    MenuItem,
    ListItemIcon,
    IconButton,
    Typography,
    Divider
} from '@mui/material';

import LogoutIcon from '@mui/icons-material/Logout';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'

import { getInitialLetterName } from '../../utils/get-initial-letters-name'

import './styles.css'
import { DialogChangePassword } from 'components/DialogChangePassword';

export function ButtonAccountMenu() {
    const { logout: handleLogout } = useAuth.getState();
    const { userData } = useSelector((state: any) => state.authentication);
    const [openChangePWDialog, setChangePWDialog] = useState(false);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    function logout() {
        sessionStorage.clear();
        handleLogout(true);
    }

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleGoLauncher = () => {
        window.location.href = process.env.REACT_APP_SSO_URL || ''
    }

    return (
        <>

            <DialogChangePassword
                isOpen={openChangePWDialog}
                onClose={() => setChangePWDialog(false)}
            />
            <IconButton
                onClick={handleClick}
                className="btn_account_menu"
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
            >
                <Avatar className='btn_account_avatar'>
                    <Typography variant="h5">
                        {getInitialLetterName(userData?.first_name ?? '', '')}
                    </Typography>
                </Avatar>

                <Typography variant="h5">
                    {`${userData?.first_name ?? ''}`}
                </Typography>

                {
                    open ?
                        <ExpandLessIcon
                            fontSize='large'
                            htmlColor='var(--neutral-1)'
                        /> :
                        <ExpandMoreIcon
                            fontSize='large'
                            htmlColor='var(--neutral-1)'
                        />
                }
            </IconButton>

            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >

                <MenuItem
                    onClick={() => {
                        setChangePWDialog(true);
                        handleClose();
                    }}
                >

                    Alterar Senha
                </MenuItem>



                <MenuItem
                    onClick={handleGoLauncher}
                >
                    Voltar ao Launcher
                </MenuItem>
                <Divider component="li" />
                <MenuItem onClick={logout}>
                    <ListItemIcon>
                        <LogoutIcon
                            fontSize="medium"
                            htmlColor="var(--primary-regular)"
                        />
                    </ListItemIcon>

                    <Typography
                        variant="h6"
                        style={{
                            fontWeight: 'bold',
                            color: 'var(--primary-regular)'
                        }}
                    >
                        Sair
                    </Typography>
                </MenuItem>
            </Menu>
        </>
    )
}
