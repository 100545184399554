import { Grid, MenuItem, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { getEmptyUserDataRegisterStudent } from "utils/userDataConverter";
import { validateUser } from "utils/userValidator";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  registerUser,
  updateUsers,
} from "store/reducers/UserRegister/AsyncActions/userRegister";
import { findGroups } from "store/reducers/Groups/AsyncActions";
import { toast } from "react-toastify";

export default function UserDialog({
  open,
  setOpen,
  dataForm,
  setDataForm,
  categories,
  userGroups,
  refreshUpdateFunction,
  typeUser
}) {
  const [confirmacaoSenha, setConfirmacaoSenha] = useState("");
  const dispatch = useDispatch();

  const { groups } = useSelector((state: any) => state.groups);

  const handleDataForm = (position: string, value: unknown) => {
    setDataForm({ ...dataForm, [position]: value });
  };

  useEffect(() => {
    dispatch(findGroups());
  }, [dispatch]);


  const handleSaveUser = (e: React.FormEvent) => {
    e.preventDefault();

    if (!dataForm.group) {
      toast.error('Necessário selecionar uma turma')

      return
    }

    let errors: string[] = validateUser(dataForm, confirmacaoSenha);
    if (errors.length > 0) alert(errors[0]);
    else {
      if (dataForm.id) {
        if (refreshUpdateFunction) {
          dispatch(
            updateUsers(dataForm, { ifSuccess: refreshUpdateFunction() })
          );
        } else {
          dispatch(updateUsers(dataForm));          
        }
      } else dispatch(registerUser(dataForm));
      cleanFormField(dataForm);
    }
  };

  const cleanFormField = (e: React.FormEvent) => {
    setDataForm(getEmptyUserDataRegisterStudent());
    setConfirmacaoSenha("");
    setOpen(false);
  };
    
  return (
    <div>
      <Dialog
        open={open}
        keepMounted
        maxWidth="md"
        fullWidth
        onClose={() => setOpen(false)}
      >
        <DialogTitle id="alert-dialog-slide-title">
          <Typography variant="h4">
            {dataForm?.id ? 'Editar Usuário' : 'Cadastrar Usuário'}
          </Typography>
        </DialogTitle>
        
        <form onSubmit={handleSaveUser}>
          <DialogContent dividers>
            {/* <Box display="flex" flexDirection="column" gap={2}> */}
            <Grid container spacing={2}>
              <Grid item md={4} xs={12}>
                <TextField
                  label="Nome"
                  required
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={dataForm.nome}
                  onChange={(e) => handleDataForm("nome", e.target.value)}
                />
              </Grid>
              
              <Grid item md={4} xs={12}>
                <TextField
                  label="Sobrenome"
                  required
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={dataForm.sobrenome}
                  onChange={(e) => handleDataForm("sobrenome", e.target.value)}
                />
              </Grid>

              <Grid item md={4} xs={12}>
                <TextField
                  label="Username"
                  required
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={dataForm.username}
                  onChange={(e) => handleDataForm("username", e.target.value)}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  label="E-mail"
                  required
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={dataForm.email}
                  onChange={(e) => handleDataForm("email", e.target.value)}
                />
              </Grid>

              {!dataForm.id ? (
                <>
                  <Grid item md={6} xs={12}>
                    <TextField
                      label="Senha"
                      required
                      fullWidth
                      variant="outlined"
                      type="password"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={dataForm.senha}
                      onChange={(e) => handleDataForm("senha", e.target.value)}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      label="Confirmação Senha"
                      required
                      fullWidth
                      variant="outlined"
                      type="password"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={confirmacaoSenha}
                      onChange={(e) => setConfirmacaoSenha(e.target.value)}
                    />
                  </Grid>
                </>
              ) : null}

              {dataForm.perfil === "coordinator" ||
                dataForm.perfil === "student" ||
                dataForm.perfil === "teacher" ? (
                <Grid item md={6} xs={12}>
                  <TextField
                    select
                    label="Turma"
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={dataForm.group}                    
                    onChange={(e) => handleDataForm("group", e.target.value)}
                  >
                    {groups.map(
                      (group) => (
                        <MenuItem key={group.id} value={group.id}>
                          {group.name}
                        </MenuItem>
                      )
                    )}
                  </TextField>
                </Grid>
              ) : null}
              
              {typeUser !== "student" ? (
                <Grid item md={6} xs={12}>
                  <TextField
                    select
                    label="Categoria"
                    required
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={dataForm.perfil}
                    onChange={(e) => handleDataForm("perfil", e.target.value)}
                  >
                    {categories.map((categoria, it) => (
                      <MenuItem key={"categoria_" + it} value={categoria}>
                        {categoria}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              ) : null},


            </Grid>




          </DialogContent>

          <DialogActions>
            <Button onClick={(e) => cleanFormField(e)} color="error">
              Cancelar
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Salvar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
