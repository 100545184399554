import {
  GroupsInterface,
  SchoolInterface,
} from "model/Groups/interfaces/groups";
import { loadCities, loadGroupsData, loadSchools } from "../Actions/action";
import classServices, { GroupParams } from "./group.services";
import ClassModel from "model/Groups/Class";
import AlertService from "services/admin/AlertService";

export const findGroups = (params?: GroupParams) => {
  return (dispatch) => {
    classServices.listGroups(params).then(({ data }: any) => {
      dispatch(loadGroupsData(data as GroupsInterface[]));
    });
  };
};

export const findGroupsSchools = (params?: GroupParams) => {
  return (dispatch) => {
    classServices.listGroups(params).then(({ data }: any) => {
      dispatch(loadSchools(data as GroupsInterface[]));
    });
  };
};

export const registerClass = (classData: ClassModel) => {
  return (dispatch: any) => {
    return classServices
      .createGroup(classData)
      .then(() => {
        AlertService.showAlertSuccessDefault({});
        dispatch(listAllGroups());
      })
      .catch((error) => {
        if (error.response) {
          AlertService.showAlertErroDefault({
            message: " - " + Object.values(error.response.data),
          });
        }
      });
  };
};

export const listAllGroups = () => {
  return (dispatch: any) => {
    return classServices
      .listGroups()
      .then((data) => {
        let groups: GroupsInterface[] = [];
        const entryes: GroupsInterface[] = data["data"]["results"];
        for (const entry of entryes) {
          groups.push(entry);
        }
        // dispatch(listUsers(users));
      })
      .catch((error) => {
        if (error.response) {
          AlertService.showAlertErroDefault({
            message: " - " + Object.values(error.response.data),
          });
        }
      });
  };
};

export const loadAllSchools = () => {
  return (dispatch: any) => {
    return classServices
      .listGroups({ type: "school" })
      .then((response) => {
        const schools: GroupsInterface[] = response["data"];
        dispatch(loadSchools(schools as GroupsInterface[]));
      })
      .catch((error) => {
        if (error.response) {
          AlertService.showAlertErroDefault({
            message: " - " + Object.values(error.response.data),
          });
        }
      });
  };
};

export const loadAllCities = () => {
  return (dispatch: any) => {
    return classServices
      .listGroups({ type: "city" })
      .then((response) => {
        const cities: GroupsInterface[] = response["data"];
        dispatch(loadCities(cities as GroupsInterface[]));
      })
      .catch((error) => {
        if (error.response) {
          AlertService.showAlertErroDefault({
            message: " - " + Object.values(error.response.data),
          });
        }
      });
  };
};

export const createSchool = (school: SchoolInterface) => {
  return (dispatch: any) => {
    return classServices
      .createSchool(school)
      .then((response) => {
        AlertService.showAlert({
          message: "Escola cadastrada com sucesso.",
          type: "success",
        });
        dispatch(loadAllSchools());
      })
      .catch((error) => {
        if (error.response) {
          AlertService.showAlertErroDefault({
            message: " - " + Object.values(error.response.data),
          });
        }
      });
  };
};

export const deleteSchool = (school: SchoolInterface) => {
  return (dispatch: any) => {
    return classServices
      .removeSchool(school)
      .then((response) => {
        AlertService.showAlert({
          message: "Escola removida com sucesso.",
          type: "success",
        });
        dispatch(loadAllSchools());
      })
      .catch((error) => {
        if (error.response) {
          AlertService.showAlertErroDefault({
            message: " - " + Object.values(error.response.data),
          });
        }
      });
  };
};

export const updateSchool = (school: SchoolInterface) => {
  return (dispatch: any) => {
    return classServices
      .updateSchool(school)
      .then((response) => {
        AlertService.showAlert({
          message: "Escola atualizada com sucesso.",
          type: "success",
        });
        dispatch(loadAllSchools());
      })
      .catch((error) => {
        if (error.response) {
          AlertService.showAlertErroDefault({
            message: " - " + Object.values(error.response.data),
          });
        }
      });
  };
};
