
import { emphasize, makeStyles, withStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import { TableCell } from '@mui/material';
import { TableRow } from "@mui/material";





export const useStyles = makeStyles((theme) => ({
	root: {
		'&:hover:hover': {
			backgroundColor: '#C8EEFA',
			boxShadow: '0px 0px 3px rgba(1, 97, 142, 0.3)',
		},
		'&:selected:hover': {
			backgroundColor: '#163447 !important',
		},
	},

	textfield: {
		flexDirection: 'inherit',
		marginLeft: 40,
		marginBottom: 40,
		marginRight: 16,
		backgroundColor: 'var(--neutral-1)',
		// width: '700px',
	},
	divSearchCss: {
		width: '70%',
		float: 'left',
		marginLeft: '1rem',
		marginBottom: '2rem',
	},
	divSearchAndNewCss: {
		marginBottom: '1rem',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: '0.4rem 1rem'
	},
	divActionButtonsCss: {
		float: 'right'
	},

	button: {
		backgroundColor: '#C8EEFA',
		color: '#1482AE',
		marginLeft: 18,
		width: 147,
		height: '42px',
		fontSize: '18px',		
		boxShadow: 'none',
		textTransform: 'capitalize',
		justifyContent: 'center',
		alignItems: 'center',
		padding: '15px 30px',
		gap: '10px',					
		fontWeight: 600,
		'&:disabled': {
			textTransform: 'capitalize',
		},																		
		'&:hover': {
			backgroundColor:'#01618E',
			color: 'white',
		},
		'&:active': {
			boxShadow: theme.shadows[1],
			backgroundColor: emphasize(theme.palette.grey[300], 0.12),
		},		

	},
	fab: {
		height: '42px',
		marginLeft: 8,
		marginRight: 8,
		backgroundColor: '#C8EEFA',
		color: '#1482AE',
		boxShadow: 'none',
		borderRadius: '6px',	
		'&:hover': {
			backgroundColor:'#01618E',
			color: 'white',
		},
		'&:active': {
			boxShadow: theme.shadows[1],
			backgroundColor: emphasize(theme.palette.grey[300], 0.12),
		},		
	},
}));


export const SearchIconCustom = withStyles({
	root: {
		color: '#A3A8AA'
	},
})(SearchIcon);

export const StyleTableCell = withStyles((theme) => ({
	root: {
		flexGrow: 1,
		fontFamily: "Quicksand",		
		
	 },
	 head: {
		backgroundColor: "#01618E",
		color: theme.palette.common.white,
		fontWeight: 600,
		lineHeight: "10px",
		fontSize: "1.2rem",
	 },
	 body: {
		fontFamily: "Quicksand",
		fontWeight: 600,
		fontSize: "1.1rem",
		color: "#163447",
		overflowY: "auto",
		overflowX: "hidden",
		// display: 'block',
	 },

}))(TableCell);

export const StyledTableRow = withStyles((theme) => ({
	root: {
	  height: 50,
	},
	body: {
	  fontSize: "2.5rem",
	},
 }))(TableRow);
