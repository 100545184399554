import { Dialog, DialogContent, DialogTitle } from "@mui/material";

import { ChangePasswordForm } from "./ChangePasswordForm";

interface IDialogChangePassword {
  isOpen?: boolean;
  onClose?: () => void;
}

export function DialogChangePassword({
  isOpen = true,
  onClose = () => { },
}: IDialogChangePassword) {
  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth >
      <DialogTitle>Alterar Senha</DialogTitle>

      <DialogContent>
        <ChangePasswordForm onSubmitForm={onClose} />
      </DialogContent>
    </Dialog>
  );
}
