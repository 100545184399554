import { FormEvent, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import {
  Button,
  TextField,
  InputAdornment,
} from "@mui/material";

import EditOutlineIcon from '@mui/icons-material/EditOutlined'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined'

import TitlePage from "components/Title/Title";
import { Modal } from "components/Modal";
import StudentForm from "./components/StudentForm";

import { findGroups } from "store/reducers/Groups/AsyncActions";
import { listUsersPaged } from "store/reducers/UserRegister/AsyncActions/userRegister";
import { IUserData } from "store/reducers/UserRegister/AsyncActions/userRegister/types";
import { selectSeries } from "store/reducers/Series/AsyncActions";
import { selectShifts } from "store/reducers/Shifts/AsyncActions";
import { IRegisterProps } from "store/reducers/Authentication/AsyncActions/types";

import { getEmptyUserData } from "utils/userDataConverter";
import { filterGroupsByUser, getGroupById, getRolesByUser } from "utils/userFormUtils";

import UserDialog from "../User/UserDialog";
import UserDeleteDialog from "../User/UserDeleteDialog";

import { IItemHeader } from "components/Table/TableHeader";
import { ITbodyTr, Table } from "components/Table";
import { SearchIconCustom } from "../User/Style";
import { useHistory } from "react-router-dom";

export interface IUserDataPagination {
  count: number;
  next: string | null;
  previous: string | null;
  results: IRegisterProps[];
}


const INITIAL_DATA_PAGINATION = {
  count: 0,
  next: "",
  previous: "",
  results: [],
};

export default function StudentPage() {
  const dispatch = useDispatch();
  const history = useHistory()
  
  const [searchByName, setSearchByName] = useState<string>("");
  const [dataUsersFiltered, setDataUsersFiltered] = useState<ITbodyTr[]>([])
  const [headersTable, setHeaderTable] = useState([
    {
      id: 1,
      title: 'Nome Completo',
    },
    {
      id: 2,
      title: 'Username',
    },
    {
      id: 3,
      title: 'E-mail',
    },
    {
      id: 4,
      title: 'Turma Vinculado',
    },
    {id: 5}
  ] as IItemHeader[])

  const [openDialogAdd, setOpenDialogAdd] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);

  const [page, setPage] = useState(1);
  
  const [configPagination, setConfigPagination] = useState(INITIAL_DATA_PAGINATION);

  const { userData } = useSelector((state: any) => state.authentication);
  const { groups } = useSelector((state: any) => state.groups);
  const dataUsers: IUserData[] = useSelector((state: any) => state.users).users;

  const [dataForm, setDataForm] = useState<IUserData>(getEmptyUserData());

  const getUsers = () => {
    dispatch(listUsersPaged(page, "student", setConfigPagination));
  };

  const handleFilter = (event?: FormEvent, clearFilters = false) => {
    if (event) event.preventDefault()

    const full_name = !searchByName.trim() || clearFilters ? '' : searchByName

    setPage(1)

    dispatch(
      listUsersPaged(
        1,
        "student",
        setConfigPagination,
        full_name
      ));
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);

    dispatch(
      listUsersPaged(
        newPage,
        'student',
        setConfigPagination,
        searchByName.trim() ? searchByName : ''
      )
    );
  };

  const getButtonsOfActionsForTable = (user: IUserData) => (
    <>
      <Button
        title="Editar"
        variant="outlined"
        color="inherit"
        size="small"
        aria-label="edit"
        startIcon={<EditOutlineIcon style={{ fontSize: '2rem' }} />}
        onClick={() => {
          setDataForm(user);
          setOpenDialogAdd(true);
        }}
      >
          Editar
      </Button>

      <Button
        title="Excluir"
        variant="outlined"
        color="inherit"
        size="small"
        aria-label="delete"
        onClick={() => {
          setDataForm(user);
          setOpenDialogDelete(true);
        }}
      >
          <DeleteOutlineIcon style={{ fontSize: '2rem' }} />
      </Button>

      <Button
        title="Avaliações"
        variant="outlined"
        color="inherit"
        size="small"
        aria-label="delete"
        onClick={() =>
          history.push(`/groups/students/${user.id}/assessments`)
        }
      >
        <AssignmentOutlinedIcon style={{ fontSize: '2rem' }} />
      </Button>
    </>
  );

  const getStructuredDataUsersForTable = (users: IUserData[]) => {
    const data = users.map((user: IUserData) => {
      return {
        tbodyTrTd: [
          {element: `${user.nome} ${user.sobrenome}`},
          {element: user.username},
          {element: user.email},
          {element: getGroupById(user.group, filterGroupsByUser(userData, groups))?.name ?? '-'},
          {element: getButtonsOfActionsForTable(user)},
        ],
      };
    });

    setDataUsersFiltered(data);
  };

  const searchUser = () => {
    let data: IUserData[] = dataUsers;

    if (userData.type === "coordinator") {
      data = data.filter((user: IUserData) => {
        return user.perfil === "student" && user.group === userData.group;
      });
    }

    /*
    if (data.length > 0) {
      const headerOrder = headersTable.filter(header => header.with_order && header.with_order !== 'init')

      if (headerOrder.length > 0) {
        const orderBy = headerOrder[0].key_of_search as any

        data = data.slice().sort(function (a, b) {
          if (a[orderBy] > b[orderBy]) {
            return headerOrder[0].with_order === 'asc' ? 1 : -1;
          }
          if (a[orderBy] < b[orderBy]) {
            return headerOrder[0].with_order === 'asc' ? -1 : 1;
          }

          return 0;
        });
      }
    }
    */

    getStructuredDataUsersForTable(data);
  };

  const handleClearFilter = () => {
    setSearchByName('')

    handleFilter(undefined, true)
  }

  useEffect(() => {
    getUsers();
    dispatch(findGroups());
    dispatch(selectSeries());
    dispatch(selectShifts());
  }, [dispatch]);

  useEffect(() => searchUser(), [dataUsers, headersTable])

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <div style={{ width: '90vw', margin: '2rem' }}>
        <TitlePage
          title={"Gerenciamento de Alunos"}
          subtitle={configPagination.count + " registro(s) encontrado(s)"}
          buttonTitle="Adicionar Aluno"
          buttonAction={() => setIsOpen(true)}
        />

        <form onSubmit={handleFilter} id="filters_students" style={{ display: 'flex', alignItems: 'center', gap: 10, padding: '2rem 0' }}>
          <TextField
            style={{ flex: 6 }}
            size="small"
            variant="outlined"
            placeholder="Pesquisar por nome, sobrenome ou email"
            value={searchByName}
            onChange={(e) => setSearchByName(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIconCustom />
                </InputAdornment>
              ),
            }}
          />

          <Button
            variant="contained"
            size="medium"
            style={{ flex: 1 }}
            type="submit"
            form="filters_students"
          >
            Pesquisar
          </Button>

          <Button
            variant="contained"
            color="secondary"
            size="medium"
            style={{ flex: 1 }}
            onClick={handleClearFilter}
          >
            Limpar
          </Button>
        </form>

        <Table
          headers={headersTable}
          tbodyTrs={dataUsersFiltered}
          pagination={{
            numberActualPage: page,
            rowsPerPage: 20,
            totalRecords: configPagination.count,
            handlePaginationChange: handleChangePage
          }}
          setHeaders={setHeaderTable}
        />

        <Modal
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          title="Cadastrar Aluno"
          withAction={false}
        >
          <StudentForm
            onSuccess={() => {
              setIsOpen(false);
            }}
          />
        </Modal>

        <UserDialog
          open={openDialogAdd}
          setOpen={setOpenDialogAdd}
          dataForm={dataForm}
          setDataForm={setDataForm}
          categories={getRolesByUser(userData)}
          userGroups={filterGroupsByUser(userData, groups)}
          refreshUpdateFunction={() => { setSearchByName('') }}
          typeUser={"student"}
        />

        <UserDeleteDialog
          open={openDialogDelete}
          setOpen={setOpenDialogDelete}
          dataForm={dataForm}
          refreshFunction={() => {
            getUsers()
            setSearchByName('')
          }}
        />
      </div>
    </div>
  );
}
