import { createReducer } from "@reduxjs/toolkit"
import { loadSeriesData, resetSeriesData } from "./Actions/action";
import { SerieInterface } from "model/Groups/interfaces/series";

const  INITIAL_STATE={
    series: [] as SerieInterface[],  
}



const series = createReducer(
    INITIAL_STATE, {
        [resetSeriesData.type]: (state)=>({...state, series: [...INITIAL_STATE.series]}),
        [loadSeriesData.type]: (state, action)=>{
            state.series = action.payload;
        }
    }
);

export default series;