import { configureStore } from "@reduxjs/toolkit";

import authentication from "./reducers/Authentication";
import admin from "./reducers/Admin";
import users from "./reducers/UserRegister";
import groups from "./reducers/Groups";
import shifts from "./reducers/Shifts";
import series from "./reducers/Series";

export default configureStore({
  reducer: {
    authentication: authentication,
    admin: admin,
    users: users,
    groups: groups,
    series: series,
    shifts: shifts,    
  },
});
