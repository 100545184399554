import { Route, Switch } from "react-router-dom";
import React from "react";
import UserList from "../User/UserList";

export default function AdminContent(): React.ReactElement {

	const [user1, setUser] = React.useState<any>(null);

    React.useEffect(()=>{
       const usuario = localStorage.getItem("@App:user");       
        if(usuario) {
            setUser(JSON.parse(usuario));
        }
    },[]);



	return (
		<Switch>
			<Route path="/" exact component={UserList} />
			<Route path="/admin/users/" exact component={UserList} />			
		</Switch>
	);
}