import React from "react";
import "./style.scss";
import { Button } from "@mui/material";

interface IProps {
  onClick?: () => void;
  closeButtonText: string;
}
export const CancelButton: React.FC<IProps> = ({
  onClick,
  closeButtonText,
}) => {
  return (
    <div className="ceModalAction">
      <Button
        variant="contained"
        color="secondary"
        onClick={() => {
          if (onClick) onClick();
        }}
        fullWidth
      >
        {closeButtonText}
      </Button>
    </div>
  );
};
