import { useEffect, useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from "@mui/material";

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutlined'
import UpdateIcon from '@mui/icons-material/Update'

import TitlePage from "components/Title/Title";
import { Modal } from "components/Modal";
import { Table } from 'components/Table'
import { IItemHeader } from "components/Table/TableHeader";

import { Assessment, Exam, Page } from "services/admin/Interfaces/Types";
import { StudentAssessmentsService } from "store/reducers/assessments/AsyncActions/assessment.service";
import StudentAssessmentFormModal from "./studentAssessmentModal";
import AuthenticationServices from "store/reducers/Authentication/AsyncActions/authentication.services";
import { IRegisterProps } from "store/reducers/Authentication/AsyncActions/types";
import StudentAssessmentDeleteDialog from "./studentAssessmentDeleteDialog";

import AlertService from "utils/Alerts/AlertService";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { formatDatePtBr } from "utils/formatDatePtBr";

const StudentAssessmentComponent = (props) => {
  const { student } = props.match.params;

  const { userData } = useSelector((state: any) => state.authentication);

  const [currentPage, setCurrentPage] = useState<Page<any>>();
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [isAddNewAssessment, setIsAddNewAssessment] = useState<boolean>(false);
  const [pageAssessments, setPageAssessments] = useState<Page<Assessment>>();
  const [pageExams, setPageExams] = useState<Page<Exam>>();
  const [user, setUser] = useState<IRegisterProps>();
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
  const [isOpenDeleteEndedAtDialog, setIsOpenDeleteEndedAtDialog] = useState(false);
  const [idStudentExamToRemove, setIdStudentExamToRemove] = useState(0);
  const [dataForRemoveEndedAt, setDataForRemoveEndedAt] = useState({
    assessmentTitle: '',
    examTitle: '',
    studentExamId: 0,
  })

  const [headersTable, setHeaderTable] = useState([
    {
      id: 1,
      title: 'Código',
    },
    {
      id: 2,
      title: 'Avaliação',
    },
    {
      id: 3,
      title: 'Exame',
    },
    {
      id: 4,
      title: 'Iniciado Em',
    },
    {
      id: 5,
      title: 'Concluído Em',
    },
    {id: 6}
  ] as IItemHeader[])

  useEffect(() => {
    fetchPageData(currentPageNumber);

    StudentAssessmentsService.findAssessments(10000, 0).then((response) => {
      setPageAssessments(response.data);
    });

    StudentAssessmentsService.findExams(10000, 0).then((response) => {
      setPageExams(response.data);
    });

    AuthenticationServices.getUserById(student).then((response) => {
      setUser(response.data);
    });
  }, [currentPageNumber]);

  const handleCloseDialogRemoveEndedAt = () => {
    setIsOpenDeleteEndedAtDialog(false)
    setDataForRemoveEndedAt({
      assessmentTitle: '',
      examTitle: '',
      studentExamId: 0,
    })
  }

  const removeEndedAt = () => {
    StudentAssessmentsService.removeEndedAtStudentExam(dataForRemoveEndedAt.studentExamId)
      .then(() => {
        toast.success('Removido data de conclusão!')
        handleCloseDialogRemoveEndedAt()

        if (currentPageNumber === 1) {
          fetchPageData(1)
        } else {
          setCurrentPageNumber(1);
        }
      })
      .catch(() => {
        toast.success('Ocorreu um erro ao realizar a remoção!')
      })
  }

  const removeNewAssessment = (idStudentExam: number) => {
    StudentAssessmentsService.removeAssessmentsByStudent(idStudentExam)
      .then((response) => {
        AlertService.showAlert({
          type: "success",
          message: "Avaliação removida com sucesso!",
        });
        fetchPageData(currentPageNumber);
      })
      .catch((error: any) => {
        AlertService.showAlert({
          type: "erro",
          message: "Não foi possivel remover Avaliação!",
        });
      });
  };

  const fetchPageData = (pageNumber: number) => {
    StudentAssessmentsService.findAssessmentsByStudent(
      student,
      10,
      (pageNumber - 1) * 10
    ).then((response) => {
      setCurrentPage(response.data as Page<any>);
    });
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setCurrentPageNumber(newPage);
  };

  const getAssessmentTitle = (idAssessment: number): string => {
    const assessment: Assessment | undefined = pageAssessments?.results.find(
      (a) => a.id === idAssessment
    );
    if (assessment) return assessment.description;
    else return "";
  };

  const getExamTitle = (idExam: number): string => {
    const exam: Exam | undefined = pageExams?.results.find(
      (a) => a.id === idExam
    );
    if (exam) return exam.description;
    else return "";
  };

  const createButtonsActionItemTable = (
    studentExamId,
    assessmentTitle,
    examTitle,
    hasEndedAt
  ) => {
    return (
      <>
        {
          (userData && userData.type === "administrator") ?
            <Button
            title="Remover Data de Conclusão"
            variant="outlined"
            color="inherit"
            size="small"
            aria-label="remover data de conclusão"
            disabled={!hasEndedAt}
            onClick={hasEndedAt ? () => {
              setDataForRemoveEndedAt({
                studentExamId,
                assessmentTitle,
                examTitle
              });
              setIsOpenDeleteEndedAtDialog(true);
            } : () => {}}
          >
              <UpdateIcon style={{ fontSize: '2rem' }} />
          </Button>
          : <></>
        }

        <Button
          title="Excluir"
          variant="outlined"
          color="inherit"
          size="small"
          aria-label="delete"
          onClick={() => {
            setIdStudentExamToRemove(studentExamId);
            setIsOpenDeleteDialog(true);
          }}
        >
            <DeleteOutlineIcon style={{ fontSize: '2rem' }} />
        </Button>
      </>
    )
  }

  const getStructuredDataUsersForTable = () => {
    let data: any = []

    if (currentPage) {
      data = (currentPage.results as any[]).map((assessment) => {
        const assessmentTitle = getAssessmentTitle(assessment.assessment)
        const examTitle = getExamTitle(assessment.exam)

        return {
          tbodyTrTd: [
            {element: assessment.code},
            {element: assessmentTitle},
            {element: examTitle},
            {element: assessment.started_at ? formatDatePtBr(assessment.started_at) : '-'},
            {element: assessment.ended_at ? formatDatePtBr(assessment.ended_at) : '-'},
            {element: createButtonsActionItemTable(assessment.id, assessmentTitle, examTitle, !!assessment.ended_at)},
          ],
        };
      });
    }

    return data
  };

  return (
    <>
      <main className="class-group-component">
        <TitlePage
          title={`Avaliações de ${user?.first_name} ${user?.last_name}`}
          subtitle={
            (currentPage ? currentPage.count : 0) + " registro(s) encontrado(s)"
          }
          buttonTitle="Adicionar"
          buttonAction={() => setIsAddNewAssessment(true)}
        />

        <Table
          headers={headersTable}
          tbodyTrs={getStructuredDataUsersForTable()}
          pagination={{
            numberActualPage: currentPageNumber,
            rowsPerPage: 10,
            totalRecords: currentPage?.count as number,
            handlePaginationChange: handleChangePage
          }}
          setHeaders={setHeaderTable}
        />
      </main>

      <Modal
        isOpen={isAddNewAssessment}
        onClose={() => setIsAddNewAssessment(false)}
        title="Adicionar nova Avaliação"
        withAction={false}
      >
        <StudentAssessmentFormModal
          student={student}
          allExams={pageExams?.results as Exam[]}
          allAssessment={pageAssessments?.results as Assessment[]}
          postCreateAction={() => {
            setIsAddNewAssessment(false);
            fetchPageData(currentPageNumber);
          }}
          onClose={() => setIsAddNewAssessment(false)}
        />
      </Modal>

      <StudentAssessmentDeleteDialog
        open={isOpenDeleteDialog}
        deleteFunction={() => {
          removeNewAssessment(idStudentExamToRemove);
          setIsOpenDeleteDialog(false);
          setIdStudentExamToRemove(0);
        }}
        onClose={() => setIsOpenDeleteDialog(false)}
        setOpen={setIsOpenDeleteDialog}
        dataForm={user}
      ></StudentAssessmentDeleteDialog>

      <Dialog
        open={isOpenDeleteEndedAtDialog}
        onClose={handleCloseDialogRemoveEndedAt}
      >
        <DialogTitle>
          <Typography variant="h5">Remover Data de Conclusão</Typography>
        </DialogTitle>

        <DialogContent dividers>
          <Typography variant="h5">
            Você deseja realmente remover a data de conclusão da avaliação <b>({dataForRemoveEndedAt.assessmentTitle} - {dataForRemoveEndedAt.examTitle})</b> do aluno(a) <b>{user?.first_name} {user?.last_name}</b>
          </Typography>
        </DialogContent>

        <DialogActions>
          <Button
            variant="text"
            color="error"
            onClick={handleCloseDialogRemoveEndedAt}
          >
            Cancelar
          </Button>

          <Button
            variant="contained"
            onClick={removeEndedAt}
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default StudentAssessmentComponent;
