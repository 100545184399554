import { ShiftInterface } from "model/Groups/interfaces/shift";
import api from "services/api";

class ShiftService {

  public listShifs = async (): Promise<ShiftInterface[]> => {
    return await api.get("groups/shifts/");
  }

  public createShift = async (props: ShiftInterface): Promise<ShiftInterface> => {
    return await api.post(
      "groups/shifts/",
      props
    );
  }

  public updateShift = async (props: ShiftInterface): Promise<ShiftInterface> => {
    return await api.patch(
      `groups/shifts/${props.id}`,
      props
    );
  }

  public deleteShift = async (props: ShiftInterface): Promise<void> => {
    return await api.delete(`groups/shifts/${props.id}/`);
  }

}
export default new ShiftService();
