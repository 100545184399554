import {
  Assessment,
  Exam,
  Page,
  StudentExam,
} from "services/admin/Interfaces/Types";
import api from "services/api";
import { IUserData } from "store/reducers/UserRegister/AsyncActions/userRegister/types";

export class StudentAssessmentsService {
  public static async findAssessmentsByStudent(
    student: number,
    limit: number,
    offset: number
  ) {
    return await api.get<Page<any>>(`/assessments/studentexams/`, {
      params: { student: student, limit: limit, offset: offset },
    });
  }

  public static async findAssessments(limit: number, offset: number) {
    return await api.get<Page<Assessment>>(`/assessments/assessments/`, {
      params: { limit: limit, offset: offset },
    });
  }

  public static async findExams(limit: number, offset: number) {
    return await api.get<Page<Exam>>(`/assessments/exams/`, {
      params: { limit: limit, offset: offset },
    });
  }

  public static async createAssessmentsByStudent(student: StudentExam) {
    return await api.post<StudentExam>(`/assessments/studentexams/`, student);
  }

  public static async removeAssessmentsByStudent(idStudentExam: number) {
    return await api.delete<Assessment[]>(
      `/assessments/studentexams/${idStudentExam}`
    );
  }
  
  public static async removeEndedAtStudentExam(studentexamId: number) {
    await api
      .patch(`assessments/studentexams/${studentexamId}/`, {
        ended_at: null,
      })
  }
}
